Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "MultipageForms";
exports.labelBodyText = "MultipageForms Body";

exports.btnExampleTitle = "CLICK ME";

exports.successMessage = "UserDetails inserted successfully";
exports.errorsDataMsg  = "Internal Server error";
exports.multiPageFormSubmitEndPoint = "/bx_block_multipageforms/user_profiles";

exports.getApiRequest = "GET";
exports.postApiRequest = "POST";
exports.getDelegateMembersApiEndPoint = "bx_block_customisableuserprofiles/delegate_members";
exports.getLegalDataApiEndPoint = "bx_block_custom_form/legal_informations";
exports.getFriendsDetailsApiEndPoint = "/bx_block_custom_form/friend_details";
exports.getMedicalDataApiEndPoint = "bx_block_custom_form/medical_informations";

exports.myDataTitle = "My Data";
exports.mapTitle = "Map";
exports.photoTitle = "Photos";
exports.viewAllLink = "View all";
exports.videoTitle = "Videos";
exports.maxFileError = "As per your plan max ${numberOfFile} will allowed."
exports.friendsDetailsTitle = "Friends Details";
exports.messageText = "This info will be added to your final booklet";
exports.friendsDetailsEditQuestion1 = "1. Name of your best friend";
exports.friendsDetailsEditQuestion2 = "2. How you met your best friend?";
exports.friendsDetailsEditQuestion3 = "3. Please describe your best friend in three or more words";
exports.friendsDetailsEditQuestion4 = "4. Add your favourite memory of you and your best friend";
exports.friendsDetailsEditQuestion5 = "5. Add more of your closest friends?";
exports.friendsDetailsEditQuestion6 = "6. Add a group memory?";
exports.locationFieldLabelFriends = "Location where you met";
exports.friendMediaAttributeLocationLabelFriends = "Location of Image";
exports.friendMediaAttributeLocationLabel = "Location of Image";
exports.friendMediaAttributeDateLabel = "Date of Image";
exports.friendMediaAttributeDateLabelFriends = "Date of Image";
exports.friendsMemoriesFormTitle = "Friends Memories";
exports.friendsClosestFriendsForm = "Closest Friends"
exports.friendsGroupMemoriesForm = "Group Memories"
exports.favouriteMemoryFormTitleFriends = "Memories";
exports.memoriesSubtitleFriends = "You can add max";
exports.memoriesTextFriends = "memories";
exports.addAnotherBtnTextFriends = "Add another";
exports.addMoreBtnTextFriends = "Add more";
exports.removeBtnTextFriends = "Remove";
exports.memoryTitleFieldLabelFriends = "Memory Title";
exports.friendMemoryLocationFieldLabelFriends = "Location";
exports.friendMemoryTimeStampFieldLabelFriends = "Time Stamp";
exports.formAPiMethod = "POST";
exports.fetchFormDataMethod="GET";
// closest friends form
exports.closestFriendsFormTitle = "Close Friend";
exports.closestFriendsSubtitle = "You can add max";
exports.closeFriendsText = "close friends";

exports.closeFriendFullNameFieldLabel = "Full name";
exports.closeFriendLocationFieldLabel = "Location where you met";
exports.aboutCloseFriendFieldLabel = "Write about first meeting with your friend";
exports.closeFriendDescriptionFieldLabel = 
"Please describe your close friend in three or more words";
exports.closeFriendMemoriesSwitchLabel = "Add memory with close friend?";

// group memories form //
exports.groupMemoriesFormTitleFriends = "Group Memory";
exports.friendNameFieldLabelFriends  = "Friend Name";
exports.investmentsAndFundsEndPoint = "bx_block_custom_form/investment_informations"
exports.investmentsAndFundsPageTitle = "Investments & Funds";
exports.haveBankingProviderQuestion = "1. Do you have a banking provider?";
exports.haveStocksQuestion = "2. Do you have any Stocks/ Shares?";
exports.havePremimumBondsQuestion = "3. Do you have any premium bonds?";
exports.bankDetailsFormTitle = "Bank Details";
exports.providerNameFieldLabel = "Provider Name";
exports.stockDetailsFormTitle = "Stock Details";
exports.stockNameFieldLabel = "Stock Name";
exports.stockQuantity = "Stock Quantity";
exports.totalValue = "Total Value";
exports.bondDetailsFormTitle = "Bond Details";
exports.holderNameFieldLabel = "Holder’s Name";
exports.unitFieldLabel = "Unit(€)";
exports.noOfUnitFieldLabel = "Number of Units";
exports.bondValueFieldLabel = "Bond Value(€)";
exports.addMoreBank = "Add more bank"
exports.addMoreBond = "Add more bond"
exports.addMoreStock = "Add more stock"
exports.removeBank = "Remove bank"
exports.removeBond = "Remove bond"
exports.removeStock = "Remove stock"
// Legal Data //
exports.legalDataTitle = "Legal Data";
exports.delegateInChargeText = "Delegate In-charge";
exports.backBtnText = "Back";
exports.editDetailsBtnText = "Edit Details";
exports.saveChangesBtnText = "Save Changes";
exports.dialogMessageText = "Your changes have been saved successfully!";
exports.dataNotAvailable = "Data is not available";


exports.haveLegalRepresentativeLabel = 
"1. Do you have a legal representative in place to help with the transition of assets and possessions to loved ones or charities?";
exports.haveWillLabel = "2. Do you have a will in place already?";
exports.haveLifeInsuranceLabel = "3. Do you have a Life Insurance?";

// LegalRepresentativeForm
exports.legalRepresentativeTitle = "Legal Representative";
exports.fullNameFieldLabel = "Full Name";
exports.emailIDFieldLabel = "Email ID";
exports.phoneNumberFieldLabel = "Phone Number";

exports.fullNamePlaceholder = "Enter representative's full name";
exports.emailIDPlaceholder = "example@gmail.com";
exports.phoneNumberPlaceholder = "0000000000";

// Kin Details Form
exports.kinDetailsTitle = "Kin Details";
exports.relationFieldLabel = "Relation to next to Kin";
exports.dateOfBirthLabel = "Date of Birth";
exports.addressLine1Label = "Address line 1";
exports.addressLine2Label = "Address line 2";
exports.landmarkLabel = "Landmark (if required)";
exports.townCityLabel = "Town/City";
exports.countryLabel = "Country";
exports.postCodeLabel = "Post Code";

exports.kinFullNamePlaceholder = "Enter Kin's full name";
exports.kinEmailIDPlaceholder = "example@gmail.com";
exports.kinPhoneNumberPlaceholder = "0000000000";
exports.kinRelationFieldPlaceholder = "Enter relationship";
exports.kinDateOfBirthPlaceholder= "DD/MM/YYYY";
exports.kinAddressLine1Placeholder = "House/Flat no.";
exports.kinAddressLine2Placeholder = "Street and Locality";
exports.kinLandmarkPlaceholder = "Landmark";
exports.kinTownCityPlaceholder = "City";
exports.kinCountryPlaceholder = "Country";
exports.kinPostCodePlaceholder = "Post Code";

// Location Of Will Form
exports.locationOfWillTitle = "Location of Will";
exports.nameOfPersonHoldingWillLabel = "Name of person holding will (if any)";
exports.additionalInformationLabel = "Additional Information (optional)";

exports.nameOfPersonHoldingWillPlaceholder = "Enter full name";
exports.additionalInformationPlaceholder = "Example - Will is stored in the family safe";
exports.willAddressLine1Placeholder = "House numberFlat no.";
exports.willAddressLine2Placeholder = "Street and Locality";
exports.willLandmarkPlaceholder = "Landmark";
exports.willTownCityPlaceholder = "City";
exports.willCountryPlaceholder = "Country";
exports.willPostCodePlaceholder = "Post Code";

// Policy Details Form
exports.policyDetailsFormTitle = "Policy Details";
exports.policyNumberLabel = "Policy Number";
exports.policyProviderLabel = "Policy Provider (Company)";
exports.agentNameLabel = "Agent Name (if any)";
exports.planNameLabel = "Plan Name";
exports.policyTermLabel = "Policy Term (Years)";
exports.paymentFrequencyLabel = "Payment Frequency";
exports.startDateLabel = "Start Date";
exports.maturityDateLabel = "Maturity Date";
exports.sumAssuredLabel = "Sum Assured";
exports.addMorePolicyBtnText = "Add more policies";
exports.removePolicyBtnText = "Remove Policy";

exports.policyNumberPlaceholder = "Enter policy number";
exports.policyProviderPlaceholder = "Enter full name of provider";
exports.agentNamePlaceholder = "Enter full name of agent";
exports.planNamePlaceholder = "Enter plan name";
exports.policyTermPlaceholder = "Enter policy term";
exports.paymentFrequencyPlaceholder = "Select payment frequency";
exports.startDatePlaceholder = "DD/MM/YYYY";
exports.maturityDatePlaceholder = "DD/MM/YYYY";
exports.sumAssuredPlaceholder = "00.00";


// validation messages
exports.delegateInchargeMsg = "Please select delegate member";
exports.fullNameMsg = "Please enter your full name";
exports.emailMsg = "Please enter email";
exports.validEmailMsg = "Please enter valid email";
exports.phoneNumberMsg = "Please enter phone number";

exports.addressline1Msg = "Please enter addressline1";
exports.addressline2Msg = "Please enter addressline2";
exports.cityMsg = "Please enter city";
exports.countryMsg = "Please select country";
exports.postcodeMsg = "Please enter a valid post code";

exports.kinFullNameMsg = "Please enter kin's full name";
exports.kinRelationMsg = "Please enter relation to next to kin";
exports.kinEmailMsg = "Please kin's enter email";
exports.kinValidEmailMsg = "Please enter kin's valid email";
exports.kinDOBMsg = "Please enter kin's date of birth";
exports.kinAddressline1Msg = "Please enter kin's addressline1";
exports.kinAddressline2Msg = "Please enter kin's addressline2";
exports.kinCityMsg = "Please enter kin's city";
exports.kinCountryMsg = "Please select kin's country";
exports.kinPostcodeMsg = "Please enter a valid post code";

exports.policyNumberMsg = "Please enter policy number";
exports.policyProviderMsg = "Please enter name of policy provider";
exports.planNameMsg = "Please enter plan name";
exports.policyTermMsg = "Please enter policy term in years";
exports.paymentFrequencyMsg = "Please select payment frequency";
exports.startDateOfPolicyMsg = "Please enter start date of policy";
exports.maturityDateOfPolicyMsg = "Please enter maturity date of policy";
exports.sumAssuredMsg = "Please enter sum assured ";

exports.familyDetailsTitle = "Family Details";
// Medical Information //
exports.medicalDataPageTitle = "Medical Information";
exports.pdfLabel = "Include this information in Final PDF";
exports.haveMedicalConditionQuestion = "1. Do you have any medical conditions?";
exports.haveRegisteredOrganDonationQuestion = 
"2. Have you registered to opt OUT of organ donation if you live in ENGLAND?";
exports.haveYouGiveBloodQuestion = "3. Do you give blood?";
exports.haveYouKnowBloodTypeQuestion = "4. Do you know blood type?";

exports.haveYouGiveBlood = "Do you give blood?"

// Medical Condition Form
exports.medicalConditionFormTitle = "Medical Condition";
exports.addMoreConditionsBtnText = "Add more conditions";
exports.removeConditionBtnText = "Remove condition";

exports.addMoreBtnText = "Add more";
exports.removeBtnText = "Remove";

exports.conditionTypeLabel = "Condition Type";
exports.conditionNameLabel = "Condition Name";
exports.levelOfConditionLabel = "Level of Condition";
exports.descriptionFieldLabel = "Description (if required)";

exports.organDonationDetailsFormTitle = "Organ donation Details";
exports.organDonationDetailsFormDescription = "This information can be found on your donation card/confirmation";

exports.idNumberFieldLabel = "ID Number";
exports.organsCheckBoxListLabel = "Organs to be donated:"; 
exports.organNameFieldLabel = "Organ Name";

exports.bloodGroupFieldLabel = "Blood Group";
exports.bloodType = "Blood Type Details";

exports.haveYouGiveBlood = "Do you give blood?"

// Medical Condition Form
exports.medicalConditionFormTitle = "Medical Condition";
exports.addMoreConditionsBtnText = "Add more conditions";
exports.removeConditionBtnText = "Remove condition";

exports.conditionTypeLabel = "Condition Type";
exports.conditionNameLabel = "Condition Name";
exports.levelOfConditionLabel = "Level of Condition";
exports.descriptionFieldLabel = "Description (if required)";

exports.organDonationDetailsFormTitle = "Organ donation Details";
exports.organDonationDetailsFormDescription = "This information can be found on your donation card/confirmation";

exports.idNumberFieldLabel = "ID Number";
exports.organsCheckBoxListLabel = "Organs to be donated:"; 
exports.organNameFieldLabel = "Organ Name";

exports.addMoreBtnText = "Add more";
exports.removeBtnText = "Remove";

exports.bloodType = "Blood Type Details";
exports.bloodGroupFieldLabel = "Blood Group";

exports.familyDetailsQuestion1 = "1. In your family, who are you closest to?";
exports.familyDetailsQuestion2 = "2. Why is this person special to you?";
exports.familyDetailsQuestion3 = "3. What do you love most about your family?";
exports.familyDetailsQuestion4 = "4. Add your favourite memory of you and your family?";
exports.familyDetailsQuestion5 = "5. Add a festive memory (eg, Christmas)";
exports.familyDetailsQuestion6 = "6. Please describe your family in three words";
exports.memoriesText = "memories";
exports.addAnotherBtnText = "Add another";
exports.remove= "Remove"
exports.favouriteMemoryFormTitle = "Memories";
exports.memoryTitleFieldText = "Memory Title";
exports.festiveMemoryFormTitle = "Festive Memory";
exports.celebrationTypeFieldText = "Celebration type";
exports.getFamilyDetailsEndPoint = "bx_block_custom_form/family_details";
exports.getSubscriptionPlanApiEndPoint = "bx_block_custom_user_subs/user_subscriptions/active_plan"; 

exports.getPersonalLetterEndPoint = "bx_block_custom_form/personal_letters";
exports.addLettersBtnText = "Add letters";
exports.personalLettersPageTitle = "Personal Letters";
exports.personalLettersPageSubtitle = "Add or upload small letters for loved ones to read once you are gone.";
exports.delegateFieldLabel = "Delegate";
exports.recipientFieldLabel = "Recipient of letter";
exports.letterFieldLabel = "Letter";
exports.haveSpecialDaySwitchLabel = "Do you wish the letter to be opened on a specific day?";
exports.specialDayFieldLabel = "Special Day/ Subject";
exports.personalLetterHeading ="Delegate In-charge of delivering letter"
exports.voiceNotes = "Voice Notes"
exports.getVoiceNotesEndPoint = "bx_block_custom_form/voice_notes"
exports.voiceErrorMessage = "As per you plan only 10 sec voice not is alowed."
exports.microphoneError = "Microphone access denied."
exports.microphoneError1 = "Error accessing microphone:"
exports.selectPromptLabel = "Select Prompts"
exports.uploadMedia = "Upload Media"
exports.dragAndDrop1 = "Only Audio with max size of 15MB are allowed"
exports.dragAndDrop2 = "Choose File"
exports.dragAndDrop3 = "Or drag & drop here"
// Customizable Area End