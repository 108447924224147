import React from "react";
import WebFooter from "../../../components/src/Footer.web";
import WebHeader from "../../../blocks/landingpage/src/WebHeader.web";
import {
    Box,
    Grid,
    Typography,
    Button,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Container

} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import GenericModal from "../../../components/src/GenericModal";
import { Field, FieldArray, Form, Formik } from "formik";
import CustomInput from "../../../components/src/CustomInput";
import { rightTickImg } from "../../../blocks/forgot-password/src/assets";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import MediaUpload from "../../../components/src/MediaUpload.web";
import { DialogWrapper, StyledRadio } from "../../../blocks/customform/src/LegalInformation.web";
import ErrorMessage from "../../../components/src/ErrorMessage";
import FamilyDetailsEditController, { Props, configJSON, IFavouriteMemoriesAttributes, IFestiveMemoriesAttributes } from "./FamilyDetailsEditController.web";
import { Wrapper, theme } from "./LegalDataEdit.web"
import BreadcrumbStepper from "../../../components/src/BreadcrumbStepper.web";
export default class FamilyDetailsEdit extends FamilyDetailsEditController {

    constructor(props: Props) {
        super(props)
    }


    render() {
        const { familyDetails, isEditFamily, familyDetailsSteps, successFamilyDialog } = this.state
        return (
            <>
                <ThemeProvider theme={theme}>
                    <Wrapper>
                        <WebHeader navigation={this.props.navigation} />
                        <Box className="container">
                            <Container>
                                <Box>
                                    <BreadcrumbStepper steps={familyDetailsSteps} onStepClick={(path) => this.handleNavigation(path)} />
                                </Box>
                                <Box className="legalDataWrapper">
                                    <Box className="pageTitle">
                                        <Typography data-test-id="legalDataTitle" variant="h3" className="pageTitleText">
                                            {configJSON.familyDetailsTitle}
                                        </Typography>
                                    </Box>
                                    <Box className="formWrapper">
                                        <Formik
                                            initialValues={familyDetails}
                                            validationSchema={this.validateFamilyDetails}
                                            onSubmit={(values) => {
                                                this.handleFamilDetailsForm(values)
                                            }}
                                            enableReinitialize={true}
                                            data-test-id="FamilyForm"
                                        >
                                            {({ values, setFieldValue }) => (
                                                <Form>
                                                    <Box className="formWrapper">
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                                                <FormLabel component="label">
                                                                    {configJSON.familyDetailsQuestion1}
                                                                </FormLabel>
                                                                <Field
                                                                    data-test-id="closestName"
                                                                    className="inputField"
                                                                    name="closestName"
                                                                    disabled={!isEditFamily}
                                                                    as={CustomInput}
                                                                    placeholder="Enter Full name"
                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                        setFieldValue(`closest_name`, e.target.value)
                                                                    }}
                                                                    value={values.closest_name}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <FormLabel component="label">
                                                                    {configJSON.familyDetailsQuestion2}
                                                                </FormLabel>
                                                                <Field
                                                                    data-test-id="reasonForSpecial"
                                                                    name="reasonForSpecial"
                                                                    className="multilineInput"
                                                                    as={CustomInput}
                                                                    multiline
                                                                    disabled={!isEditFamily}
                                                                    placeholder="eg, They are always there when I need cheering up'"
                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                        setFieldValue(`reason_for_special`, e.target.value)
                                                                    }}
                                                                    value={values.reason_for_special}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <FormLabel component="label">
                                                                    {configJSON.familyDetailsQuestion3}
                                                                </FormLabel>
                                                                <Field
                                                                    data-test-id="about"
                                                                    name="about"
                                                                    className="multilineInput"
                                                                    as={CustomInput}
                                                                    multiline
                                                                    disabled={!isEditFamily}
                                                                    placeholder="Eg, No matter what, we are always there for each other"
                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                        setFieldValue(`about`, e.target.value)
                                                                    }}
                                                                    value={values.about}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                {isEditFamily && (
                                                                    <Box>
                                                                        <FormLabel component="label" className="formRadioLabel">
                                                                            {configJSON.familyDetailsQuestion4}
                                                                        </FormLabel>
                                                                        <RadioGroup
                                                                            data-test-id="haveFavMemory"
                                                                            name="haveLegalRepresentative"
                                                                            aria-label="haveLegalRepresentative"
                                                                            value={values.have_favourite_memory}
                                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                                setFieldValue("have_favourite_memory", e.target.value);
                                                                            }
                                                                            }
                                                                            className="radioGroup"
                                                                        >
                                                                            <FormControlLabel
                                                                                control={<StyledRadio />}
                                                                                label="Yes"
                                                                                value="1"
                                                                                className="radioLabel"
                                                                            />
                                                                            <FormControlLabel
                                                                                control={<StyledRadio />}
                                                                                label="No"
                                                                                value="0"
                                                                                className="radioLabel"
                                                                            />
                                                                        </RadioGroup>
                                                                    </Box>
                                                                )}

                                                                {values.have_favourite_memory === "1" &&
                                                                    <FieldArray name="favourite_memories_attributes" data-test-id="favMemoriesFieldArray">
                                                                        {({ remove, push }) => (
                                                                            values.favourite_memories_attributes.map((favMemory: IFavouriteMemoriesAttributes, _index: number) => {
                                                                                return (
                                                                                    <Box mb={3} className={this.getClassName()}>
                                                                                        <Grid container spacing={2}>
                                                                                            <Grid item xs={12} className="policyDetailsHeaderWrapper">
                                                                                                <Typography data-test-id="favMemoriesFormTitle" variant={this.getVaraintName()}>
                                                                                                    {configJSON.favouriteMemoryFormTitle}
                                                                                                </Typography>
                                                                                                {
                                                                                                    isEditFamily && <>
                                                                                                        {this.getMaxFavMem(_index, values.favourite_memories_attributes.length) ? (
                                                                                                            <Button
                                                                                                                className="addMoreBtn"
                                                                                                                data-test-id="addMoreFavMemoriesBtn"
                                                                                                                onClick={() => push({
                                                                                                                    title: "",
                                                                                                                    files: [],
                                                                                                                })}
                                                                                                            >
                                                                                                                <AddCircleOutlineIcon className="circleOutlineIcon" />
                                                                                                                {configJSON.addAnotherBtnText}
                                                                                                            </Button>
                                                                                                        ) : (
                                                                                                            <Button
                                                                                                                data-test-id="removeFavMemBtn"
                                                                                                                className="addMoreBtn"
                                                                                                                onClick={() => remove(_index)}>
                                                                                                                <RemoveCircleOutlineIcon className="circleOutlineIcon" />
                                                                                                                {configJSON.remove}
                                                                                                            </Button>)}
                                                                                                    </>
                                                                                                }
                                                                                            </Grid>
                                                                                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                                                                                <FormLabel component="label">
                                                                                                    {configJSON.memoryTitleFieldText}<span style={{ color: "red" }}>*</span>
                                                                                                </FormLabel>
                                                                                                <Field
                                                                                                    data-test-id="memoryTitle"
                                                                                                    className="inputField"
                                                                                                    name="memoryTitle"
                                                                                                    disabled={!isEditFamily}
                                                                                                    as={CustomInput}
                                                                                                    placeholder="Enter here"
                                                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                                                        setFieldValue(`favourite_memories_attributes.${_index}.title`, e.target.value)
                                                                                                    }}
                                                                                                    value={favMemory.title}
                                                                                                />
                                                                                                <ErrorMessage name={`favourite_memories_attributes.${_index}.title`} />
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                        <Grid item xs={12}>
                                                                                            <MediaUpload
                                                                                                data-test-id="mediaUpload"
                                                                                                files={favMemory.files}
                                                                                                onUpload={(event: React.ChangeEvent<HTMLInputElement>) => this.handleFamilyDetailsUpload(event, setFieldValue, _index, favMemory.files, "favourite_memories_attributes")}
                                                                                                readonly={!isEditFamily}
                                                                                                title={this.getTitle()}
                                                                                            />
                                                                                            {this.getFileLength(favMemory.files.length) && (
                                                                                                <Box>
                                                                                                    <Typography variant="subtitle1" align="center" className="dataNotAvailable">
                                                                                                        {configJSON.dataNotAvailable}
                                                                                                    </Typography>
                                                                                                </Box>
                                                                                            )}
                                                                                        </Grid>

                                                                                    </Box>
                                                                                );
                                                                            })
                                                                        )}
                                                                    </FieldArray>}

                                                            </Grid>

                                                            <Grid item xs={12}>
                                                                {isEditFamily && (
                                                                    <Box>
                                                                        <FormLabel component="label" className="formRadioLabel">
                                                                            {configJSON.familyDetailsQuestion5}
                                                                        </FormLabel>
                                                                        <RadioGroup
                                                                            data-test-id="haveFavFest"
                                                                            name="haveLegalRepresentative"
                                                                            aria-label="haveLegalRepresentative"
                                                                            value={values.have_festive_memory}
                                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                                setFieldValue("have_festive_memory", e.target.value);
                                                                            }
                                                                            }
                                                                            className="radioGroup"
                                                                        >
                                                                            <FormControlLabel
                                                                                control={<StyledRadio />}
                                                                                label="Yes"
                                                                                value="1"
                                                                                className="radioLabel"
                                                                            />
                                                                            <FormControlLabel
                                                                                control={<StyledRadio />}
                                                                                label="No"
                                                                                value="0"
                                                                                className="radioLabel"
                                                                            />
                                                                        </RadioGroup>
                                                                    </Box>
                                                                )}

                                                                {values.have_festive_memory === "1" &&
                                                                    <FieldArray name="festive_memories_attributes" data-test-id="favMemoriesFieldArray">
                                                                    {({ remove, push }) => (
                                                                        values.festive_memories_attributes.map((fesMemory: IFestiveMemoriesAttributes, _index: number) => {
                                                                            return (
                                                                                <Box mb={3} className={this.getClassName()}>
                                                                                    <Grid container spacing={2}>
                                                                                        <Grid item xs={12} className="policyDetailsHeaderWrapper">
                                                                                            <Typography data-test-id="favMemoriesFormTitle" variant={this.getVaraintName()}>
                                                                                                {configJSON.festiveMemoryFormTitle}
                                                                                            </Typography>
                                                                                            {
                                                                                                isEditFamily && <>
                                                                                                    {_index === 0 ? (
                                                                                                        <Button
                                                                                                            className="addMoreBtn"
                                                                                                            data-test-id="addMoreFesMemoriesBtn"
                                                                                                            onClick={() => push({
                                                                                                                title: "",
                                                                                                                files: [],
                                                                                                            })}
                                                                                                        >
                                                                                                            <AddCircleOutlineIcon className="circleOutlineIcon" />
                                                                                                            {configJSON.addAnotherBtnText}
                                                                                                        </Button>
                                                                                                    ) : (
                                                                                                        <Button
                                                                                                            data-test-id="removeFesMemories"
                                                                                                            className="addMoreBtn"
                                                                                                            onClick={() => remove(_index)}>
                                                                                                            <RemoveCircleOutlineIcon className="circleOutlineIcon" />
                                                                                                            {configJSON.remove}
                                                                                                        </Button>)}
                                                                                                </>
                                                                                            }
                                                                                        </Grid>
                                                                                        <Grid item xs={12} sm={6} md={4} lg={3}>
                                                                                            <FormLabel component="label">
                                                                                                {configJSON.celebrationTypeFieldText}<span style={{ color: "red" }}>*</span>
                                                                                            </FormLabel>
                                                                                            <Field
                                                                                                data-test-id="celebrationType"
                                                                                                className="inputField"
                                                                                                name="celebrationType"
                                                                                                disabled={!isEditFamily}
                                                                                                as={CustomInput}
                                                                                                placeholder="Enter here"
                                                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                                                    setFieldValue(`festive_memories_attributes.${_index}.celebration_type`, e.target.value)
                                                                                                }}
                                                                                                value={fesMemory.celebration_type}
                                                                                            />
                                                                                            <ErrorMessage name={`festive_memories_attributes.${_index}.celebration_type`} />
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                    <Grid item xs={12}>
                                                                                        <MediaUpload
                                                                                            data-test-id="mediaUpload"
                                                                                            files={fesMemory.files}
                                                                                            onUpload={(event: React.ChangeEvent<HTMLInputElement>) => this.handleFamilyDetailsUpload(event, setFieldValue, _index, fesMemory.files, "festive_memories_attributes")}
                                                                                            readonly={!isEditFamily}
                                                                                            title={this.getTitle()}
                                                                                        />
                                                                                        {this.getFileLength(fesMemory.files.length) && (
                                                                                            <Box>
                                                                                                <Typography variant="subtitle1" align="center" className="dataNotAvailable">
                                                                                                    {configJSON.dataNotAvailable}
                                                                                                </Typography>
                                                                                            </Box>
                                                                                        )}
                                                                                    </Grid>

                                                                                </Box>
                                                                            );
                                                                        })
                                                                    )}
                                                                </FieldArray>}

                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <FormLabel component="label">
                                                                    {configJSON.familyDetailsQuestion6}
                                                                </FormLabel>
                                                                <Field
                                                                    data-test-id="description"
                                                                    name="description"
                                                                    className="multilineInput"
                                                                    as={CustomInput}
                                                                    multiline
                                                                    disabled={!isEditFamily}
                                                                    placeholder="Max 3 words"
                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                        setFieldValue(`description`, e.target.value)
                                                                    }}
                                                                    value={values.description}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Box className="btnWrapper" mt="20px">
                                                            <Button
                                                                data-test-id="cancelFamily"
                                                                variant="outlined"
                                                                className="btnSize"
                                                                onClick={() => this.handleNavigation("HomePage")}
                                                            >
                                                                {configJSON.backBtnText}
                                                            </Button>
                                                            {isEditFamily ?
                                                                <Button
                                                                    variant="contained"
                                                                    type="submit"
                                                                    className="btnSize"
                                                                >
                                                                    {configJSON.saveChangesBtnText}
                                                                </Button> :
                                                                <Button
                                                                    data-test-id="editFamily"
                                                                    variant="contained"
                                                                    type="button"
                                                                    onClick={(event) => this.setEditForm(event)}
                                                                    className="btnSize"
                                                                >
                                                                    {configJSON.editDetailsBtnText}
                                                                </Button>
                                                            }
                                                        </Box>

                                                    </Box>
                                                </Form>)}
                                        </Formik>
                                    </Box>
                                </Box>
                            </Container>
                        </Box>
                        <WebFooter
                            handleNavigation={this.handleNavigation}
                        />
                    </Wrapper>
                    <GenericModal open={successFamilyDialog} data-test-id="investmentFunds" onClose={this.closeSuccessFamilyDialog}>
                        <ThemeProvider theme={theme}>
                            <DialogWrapper>
                                <Box className="dialogContentWrapper">
                                    <Box className="dialogDescriptionWrapper">
                                        <img alt="success" src={rightTickImg} style={{ width: "56px", height: "56px", margin: "0px auto" }} />
                                        <Typography align="center" variant="body2" data-test-id="dialogMessage">
                                            {configJSON.dialogMessageText}
                                        </Typography>
                                    </Box>
                                </Box>
                            </DialogWrapper>
                        </ThemeProvider>
                    </GenericModal>
                </ThemeProvider>
            </>
        )
    }
}
