import React from "react";
import WebFooter from "../../../components/src/Footer.web";
import WebHeader from "../../../blocks/landingpage/src/WebHeader.web";
import {
    Box,
    Grid,
    Typography,
    Button,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Container

} from "@material-ui/core";
import { ThemeProvider} from "@material-ui/core/styles";
import GenericModal from "../../../components/src/GenericModal";
import { Field, FieldArray, Form, Formik} from "formik";
import CustomInput from "../../../components/src/CustomInput";
import { rightTickImg } from "../../../blocks/forgot-password/src/assets";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import MediaUpload from "../../../components/src/MediaUpload.web";
import { DialogWrapper, StyledRadio } from "../../../blocks/customform/src/LegalInformation.web";
import ErrorMessage from "../../../components/src/ErrorMessage";
import InvestmentsAndFundsEditController, { Props, configJSON, IBankingProvider, IStock, IBond } from "./InvestmentsAndFundsEditController.web";
import { Wrapper, theme } from "./LegalDataEdit.web"
import BreadcrumbStepper from "../../../components/src/BreadcrumbStepper.web";
export default class InvestmentsAndFundsEdit extends InvestmentsAndFundsEditController {

    constructor(props: Props) {
        super(props)
    }


    render() {
        const dialogStyle={ width: "56px", height: "56px", margin: "0px auto" }
        const { investmentAndFunds, isEditInvestments, investmentSteps, successDialog } = this.state
        return (
            <>
                <ThemeProvider theme={theme}>
                    <Wrapper>
                        <WebHeader navigation={this.props.navigation} />
                        <Box className="container">
                            <Container>
                                <Box>
                                    <BreadcrumbStepper steps={investmentSteps} onStepClick={(path) => this.handleNavigation(path)} />
                                </Box>
                                <Box className="legalDataWrapper">
                                    <Box className="pageTitle">
                                        <Typography data-test-id="legalDataTitle" variant="h3" className="pageTitleText">
                                            {configJSON.investmentsAndFundsPageTitle}
                                        </Typography>
                                    </Box>
                                    <Box className="formWrapper">
                                        <Formik
                                            initialValues={investmentAndFunds}
                                            validationSchema={this.validateInvestments}
                                            onSubmit={(values) => {
                                                this.handleInvestmentsForm(values)
                                            }}
                                            enableReinitialize={true}
                                            data-test-id="investmentsForm"
                                        >
                                            {({ values, setFieldValue }) => (
                                                <Form>
                                                    <Box className="formWrapper">
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12}>
                                                                {isEditInvestments && (
                                                                    <Box>
                                                                        <FormLabel component="label" className="formRadioLabel">
                                                                            {configJSON.haveBankingProviderQuestion}
                                                                        </FormLabel>
                                                                        <RadioGroup
                                                                            data-test-id="haveBankingProvide"
                                                                            name="haveLegalRepresentative"
                                                                            aria-label="haveLegalRepresentative"
                                                                            value={values.investment.have_banking_provider}
                                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                                setFieldValue("investment.have_banking_provider", e.target.value);
                                                                            }
                                                                            }
                                                                            className="radioGroup"
                                                                        >
                                                                            <FormControlLabel
                                                                                control={<StyledRadio />}
                                                                                label="Yes"
                                                                                value="1"
                                                                                className="radioLabel"
                                                                            />
                                                                            <FormControlLabel
                                                                                control={<StyledRadio />}
                                                                                label="No"
                                                                                value="0"
                                                                                className="radioLabel"
                                                                            />
                                                                        </RadioGroup>
                                                                    </Box>
                                                                )}
                                                                {!isEditInvestments && values.investment.have_banking_provider === "0" && (
                                                                    <Box>
                                                                        <Typography variant="h2">
                                                                            {configJSON.bankDetailsFormTitle}
                                                                        </Typography>
                                                                        <Typography variant="subtitle1" align="center" className="dataNotAvailable">
                                                                            {configJSON.dataNotAvailable}
                                                                        </Typography>
                                                                    </Box>
                                                                )}
                                                                {values.investment.have_banking_provider === "1" &&
                                                                    <FieldArray name="banking_provider" data-test-id="bankDetailsFieldArray">
                                                                        {({ remove, push }) => (
                                                                            values.banking_provider.map((bankProvider: IBankingProvider, index: number) => {
                                                                                return (
                                                                                    <Box mb={3} className={this.getClassName()}>
                                                                                        <Grid container spacing={2}>
                                                                                            <Grid item xs={12} className="policyDetailsHeaderWrapper">
                                                                                                <Typography data-test-id="bankDetailsFormTitle" variant={this.getVaraintName()}>
                                                                                                    {configJSON.bankDetailsFormTitle}
                                                                                                </Typography>
                                                                                                {
                                                                                                    isEditInvestments && <>
                                                                                                        {index === 0 ? (
                                                                                                            <Button
                                                                                                                className="addMoreBtn"
                                                                                                                data-test-id="addMoreBankBtn"
                                                                                                                onClick={() => push({
                                                                                                                    provider_name: "",
                                                                                                                })}
                                                                                                            >
                                                                                                                <AddCircleOutlineIcon className="circleOutlineIcon" />
                                                                                                                {configJSON.addMoreBank}
                                                                                                            </Button>
                                                                                                        ) : (
                                                                                                            <Button
                                                                                                                data-test-id="removeBankProvidersBtn"
                                                                                                                className="addMoreBtn"
                                                                                                                onClick={() => remove(index)}>
                                                                                                                <RemoveCircleOutlineIcon className="circleOutlineIcon" />
                                                                                                                {configJSON.removeBank}
                                                                                                            </Button>)}
                                                                                                    </>
                                                                                                }
                                                                                            </Grid>
                                                                                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                                                                                <FormLabel component="label">{configJSON.providerNameFieldLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                                                                                                <Field
                                                                                                    data-test-id="providerName"
                                                                                                    name="providerName"
                                                                                                    className="inputField"
                                                                                                    size="small"
                                                                                                    as={CustomInput}
                                                                                                    disabled={!isEditInvestments}
                                                                                                    placeholder="Enter your banking provider"
                                                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                                                        setFieldValue(`banking_provider.${index}.provider_name`, e.target.value)
                                                                                                    }}
                                                                                                    value={bankProvider.provider_name}
                                                                                                />
                                                                                                <ErrorMessage name={`banking_provider.${index}.provider_name`} />
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Box>
                                                                                );
                                                                            })
                                                                        )}
                                                                    </FieldArray>}

                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                {isEditInvestments && (
                                                                    <Box>
                                                                        <FormLabel component="label" className="formRadioLabel">
                                                                            {configJSON.haveStocksQuestion}
                                                                        </FormLabel>
                                                                        <RadioGroup
                                                                            data-test-id="haveStocksDetails"
                                                                            name="haveLegalRepresentative"
                                                                            aria-label="haveLegalRepresentative"
                                                                            value={values.investment.have_stocks}
                                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                                setFieldValue("investment.have_stocks", e.target.value);
                                                                            }
                                                                            }
                                                                            className="radioGroup"
                                                                        >
                                                                            <FormControlLabel
                                                                                control={<StyledRadio />}
                                                                                label="Yes"
                                                                                value="1"
                                                                                className="radioLabel"
                                                                            />
                                                                            <FormControlLabel
                                                                                control={<StyledRadio />}
                                                                                label="No"
                                                                                value="0"
                                                                                className="radioLabel"
                                                                            />
                                                                        </RadioGroup>
                                                                    </Box>
                                                                )}
                                                                {!isEditInvestments && values.investment.have_stocks === "0" && (
                                                                    <Box>
                                                                        <Typography variant="h2">
                                                                            {configJSON.stockDetailsFormTitle}
                                                                        </Typography>
                                                                        <Typography variant="subtitle1" align="center" className="dataNotAvailable">
                                                                            {configJSON.dataNotAvailable}
                                                                        </Typography>
                                                                    </Box>
                                                                )}
                                                                {values.investment.have_stocks === "1" &&
                                                                    <FieldArray name="stocks" data-test-id="bankDetailsFieldArray">
                                                                        {({ remove, push }) => (
                                                                            values.stocks.map((stock: IStock, index: number) => {
                                                                                return (
                                                                                    <Box mb={3} className={this.getClassName()}>
                                                                                        <Grid container spacing={2}>
                                                                                            <Grid item xs={12} className="policyDetailsHeaderWrapper">
                                                                                                <Typography data-test-id="bankDetailsFormTitle" variant={this.getVaraintName()}>
                                                                                                    {configJSON.stockDetailsFormTitle}
                                                                                                </Typography>
                                                                                                {
                                                                                                    isEditInvestments && <>
                                                                                                        {index === 0 ? (
                                                                                                            <Button
                                                                                                                className="addMoreBtn"
                                                                                                                data-test-id="addMoreStockBtn"
                                                                                                                onClick={() => push({
                                                                                                                    name: "",
                                                                                                                    quantity: "",
                                                                                                                    total_value: "",
                                                                                                                })}
                                                                                                            >
                                                                                                                <AddCircleOutlineIcon className="circleOutlineIcon" />
                                                                                                                {configJSON.addMoreStock}
                                                                                                            </Button>
                                                                                                        ) : (
                                                                                                            <Button
                                                                                                                data-test-id="removeStockBtn"
                                                                                                                className="addMoreBtn"
                                                                                                                onClick={() => remove(index)}>
                                                                                                                <RemoveCircleOutlineIcon className="circleOutlineIcon" />
                                                                                                                {configJSON.removeStock}
                                                                                                            </Button>)}
                                                                                                    </>
                                                                                                }
                                                                                            </Grid>
                                                                                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                                                                                <FormLabel component="label">
                                                                                                    {configJSON.stockNameFieldLabel}<span style={{ color: "red" }}>*</span>
                                                                                                </FormLabel>
                                                                                                <Field
                                                                                                    data-test-id="stockName"
                                                                                                    className="inputField"
                                                                                                    name="stockName"
                                                                                                    disabled={!isEditInvestments}
                                                                                                    as={CustomInput}
                                                                                                    placeholder="Enter bond holder name"
                                                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                                                        setFieldValue(`stocks.${index}.name`, e.target.value)
                                                                                                    }}
                                                                                                    value={stock.name}
                                                                                                />
                                                                                                <ErrorMessage name={`stocks.${index}.name`} />
                                                                                            </Grid>
                                                                                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                                                                                <FormLabel component="label">
                                                                                                    {configJSON.stockQuantity}<span style={{ color: "red" }}>*</span>
                                                                                                </FormLabel>
                                                                                                <Field
                                                                                                    data-test-id="stockQuantity"
                                                                                                    className="inputField"
                                                                                                    name="stockQuantity"
                                                                                                    disabled={!isEditInvestments}
                                                                                                    as={CustomInput}
                                                                                                    placeholder="Quantity"
                                                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                                                        setFieldValue(`stocks.${index}.quantity`, e.target.value)
                                                                                                    }}
                                                                                                    value={stock.quantity}
                                                                                                />
                                                                                                <ErrorMessage name={`stocks.${index}.quantity`} />
                                                                                            </Grid>
                                                                                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                                                                                <FormLabel component="label">
                                                                                                    {configJSON.totalValue}<span style={{ color: "red" }}>*</span>
                                                                                                </FormLabel>
                                                                                                <Field
                                                                                                    data-test-id="totalValue"
                                                                                                    className="inputField"
                                                                                                    name="totalValue"
                                                                                                    disabled={!isEditInvestments}
                                                                                                    as={CustomInput}
                                                                                                    placeholder="00.00"
                                                                                                    type="number"
                                                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                                                        setFieldValue(`stocks.${index}.total_value`, e.target.value)
                                                                                                    }}
                                                                                                    value={stock.total_value}
                                                                                                />
                                                                                                <ErrorMessage name={`stocks.${index}.total_value`} />
                                                                                            </Grid>
                                                                                        </Grid>

                                                                                    </Box>
                                                                                );
                                                                            })
                                                                        )}
                                                                    </FieldArray>}

                                                            </Grid>

                                                            <Grid item xs={12}>
                                                                {isEditInvestments && (
                                                                    <Box>
                                                                        <FormLabel component="label" className="formRadioLabel">
                                                                            {configJSON.havePremimumBondsQuestion}
                                                                        </FormLabel>
                                                                        <RadioGroup
                                                                            data-test-id="haveLegalRepresentative"
                                                                            name="haveLegalRepresentative"
                                                                            aria-label="haveLegalRepresentative"
                                                                            value={values.investment.have_premimum_bonds}
                                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                                setFieldValue("investment.have_premimum_bonds", e.target.value);
                                                                            }
                                                                            }
                                                                            className="radioGroup"
                                                                        >
                                                                            <FormControlLabel
                                                                                control={<StyledRadio />}
                                                                                label="Yes"
                                                                                value="1"
                                                                                className="radioLabel"
                                                                            />
                                                                            <FormControlLabel
                                                                                control={<StyledRadio />}
                                                                                label="No"
                                                                                value="0"
                                                                                className="radioLabel"
                                                                            />
                                                                        </RadioGroup>
                                                                    </Box>
                                                                )}
 {!isEditInvestments && values.investment.have_premimum_bonds === "0" && (
                                                                    <Box>
                                                                        <Typography variant="h2">
                                                                            {configJSON.bondDetailsFormTitle}
                                                                        </Typography>
                                                                        <Typography variant="subtitle1" align="center" className="dataNotAvailable">
                                                                            {configJSON.dataNotAvailable}
                                                                        </Typography>
                                                                    </Box>
                                                                )}
                                                                {values.investment.have_premimum_bonds === "1" &&
                                                                    <FieldArray name="bonds" data-test-id="bankDetailsFieldArray">
                                                                        {({ remove, push }) => (
                                                                            values.bonds.map((bond: IBond, _index: number) => {
                                                                                return (
                                                                                    <Box mb={3} className={this.getClassName()}>
                                                                                        <Grid container spacing={2}>
                                                                                            <Grid item xs={12} className="policyDetailsHeaderWrapper">
                                                                                                <Typography data-test-id="bankDetailsFormTitle" variant={this.getVaraintName()}>
                                                                                                    {configJSON.bondDetailsFormTitle}
                                                                                                </Typography>
                                                                                                {
                                                                                                    isEditInvestments && <>
                                                                                                        {_index === 0 ? (
                                                                                                            <Button
                                                                                                                className="addMoreBtn"
                                                                                                                data-test-id="addMoreBondBtn"
                                                                                                                onClick={() => push({
                                                                                                                    holder_name: "",
                                                                                                                    unit: "",
                                                                                                                    no_of_unit: "",
                                                                                                                    bond_value: "",
                                                                                                                    files: [],
                                                                                                                })}
                                                                                                            >
                                                                                                                <AddCircleOutlineIcon className="circleOutlineIcon" />
                                                                                                                {configJSON.addMoreBond}
                                                                                                            </Button>
                                                                                                        ) : (
                                                                                                            <Button
                                                                                                                data-test-id="removeBondBtn"
                                                                                                                className="addMoreBtn"
                                                                                                                onClick={() => remove(_index)}>
                                                                                                                <RemoveCircleOutlineIcon className="circleOutlineIcon" />
                                                                                                                {configJSON.removeBond}
                                                                                                            </Button>)}
                                                                                                    </>
                                                                                                }
                                                                                            </Grid>
                                                                                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                                                                                <FormLabel component="label">
                                                                                                    {configJSON.holderNameFieldLabel}<span style={{ color: "red" }}>*</span>
                                                                                                </FormLabel>
                                                                                                <Field
                                                                                                    data-test-id="holderName"
                                                                                                    className="inputField"
                                                                                                    name="holderName"
                                                                                                    disabled={!isEditInvestments}
                                                                                                    as={CustomInput}
                                                                                                    placeholder="Enter bond holder name"
                                                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                                                        setFieldValue(`bonds.${_index}.holder_name`, e.target.value)
                                                                                                    }}
                                                                                                    value={bond.holder_name}
                                                                                                />
                                                                                                <ErrorMessage name={`bonds.${_index}.holder_name`} />
                                                                                            </Grid>
                                                                                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                                                                                <FormLabel component="label">
                                                                                                    {configJSON.unitFieldLabel}<span style={{ color: "red" }}>*</span>
                                                                                                </FormLabel>
                                                                                                <Field
                                                                                                    data-test-id="unit"
                                                                                                    className="inputField"
                                                                                                    name="unit"
                                                                                                    disabled={!isEditInvestments}
                                                                                                    as={CustomInput}
                                                                                                    placeholder="Enter your unit value"
                                                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                                                        setFieldValue(`bonds.${_index}.unit`, e.target.value)
                                                                                                    }}
                                                                                                    value={bond.unit}
                                                                                                />
                                                                                                <ErrorMessage name={`bonds.${_index}.unit`} />
                                                                                            </Grid>
                                                                                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                                                                                <FormLabel component="label">
                                                                                                    {configJSON.noOfUnitFieldLabel}<span style={{ color: "red" }}>*</span>
                                                                                                </FormLabel>
                                                                                                <Field
                                                                                                    data-test-id="noOfUnit"
                                                                                                    className="inputField"
                                                                                                    name="noOfUnit"
                                                                                                    disabled={!isEditInvestments}
                                                                                                    as={CustomInput}
                                                                                                    placeholder="Enter number of units"
                                                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                                                        setFieldValue(`bonds.${_index}.no_of_unit`, e.target.value)
                                                                                                    }}
                                                                                                    value={bond.no_of_unit}
                                                                                                />
                                                                                                <ErrorMessage name={`bonds.${_index}.no_of_unit`} />
                                                                                            </Grid>
                                                                                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                                                                                <FormLabel component="label">
                                                                                                    {configJSON.bondValueFieldLabel}<span style={{ color: "red" }}>*</span>
                                                                                                </FormLabel>
                                                                                                <Field
                                                                                                    data-test-id="bondValue"
                                                                                                    className="inputField"
                                                                                                    name="bondValue"
                                                                                                    as={CustomInput}
                                                                                                    disabled={!isEditInvestments}
                                                                                                    placeholder="Enter your bond value"
                                                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                                                        setFieldValue(`bonds.${_index}.bond_value`, e.target.value)
                                                                                                    }}
                                                                                                    value={bond.bond_value}
                                                                                                />
                                                                                                <ErrorMessage name={`bonds.${_index}.bond_value`} />
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                        <Grid item xs={12}>
                                                                                            <MediaUpload
                                                                                                data-test-id="mediaUpload"
                                                                                                files={bond.files}
                                                                                                onUpload={(event: React.ChangeEvent<HTMLInputElement>) => this.handleInvestmentUpload(event, setFieldValue, _index, bond.files)}
                                                                                                readonly={!isEditInvestments}
                                                                                                title={this.getTitle()}
                                                                                            />
                                                                                            {this.getFileLength(bond.files.length) && (
                                                                                                <Box>
                                                                                                    <Typography variant="subtitle1" align="center" className="dataNotAvailable">
                                                                                                        {configJSON.dataNotAvailable}
                                                                                                    </Typography>
                                                                                                </Box>
                                                                                            )}
                                                                                        </Grid>

                                                                                    </Box>
                                                                                );
                                                                            })
                                                                        )}
                                                                    </FieldArray>}

                                                            </Grid>
                                                        </Grid>
                                                        <Box className="btnWrapper">
                                                            <Button
                                                                data-test-id="cancelChanges"
                                                                variant="outlined"
                                                                className="btnSize"
                                                                onClick={() => this.handleNavigation("HomePage")}
                                                            >
                                                                {configJSON.backBtnText}
                                                            </Button>
                                                            {isEditInvestments ? 
                                                            <Button
                                                                data-test-id="saveChangesButton"
                                                                variant="contained"
                                                                type="submit"
                                                                className="btnSize"
                                                            >
                                                                {configJSON.saveChangesBtnText}
                                                            </Button> : 
                                                                <Button
                                                                data-test-id="editInvestment"
                                                                variant="contained"
                                                                type="button"
                                                                onClick={(event)=> this.setEditForm(event)}
                                                                className="btnSize"
                                                            >
                                                                {configJSON.editDetailsBtnText}
                                                            </Button>
                                                            }
                                                        </Box>

                                                    </Box>
                                                </Form>)}
                                        </Formik>
                                    </Box>
                                </Box>
                            </Container>
                        </Box>
                        <WebFooter
                            handleNavigation={this.handleNavigation}
                        />
                    </Wrapper>
                    <GenericModal open={successDialog} data-test-id="investmentFunds" onClose={this.closeSuccessDialog}>
            <ThemeProvider theme={theme}>
                <DialogWrapper>
                  <Box className="dialogContentWrapper">
                    <Box className="dialogDescriptionWrapper">
                        <img alt="success"
                        data-test-id="susscessClick"
                         src={rightTickImg} style={dialogStyle} />
                        <Typography align="center" variant="body2">
                          {configJSON.dialogMessageText}
                        </Typography>
                    </Box>
                  </Box>
                </DialogWrapper>
            </ThemeProvider>
      </GenericModal>
                </ThemeProvider>
            </>
        )
    }
}
