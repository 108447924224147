import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { sendAPIRequest } from "../../../components/src/Utils";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";

interface IAboutMe {
    have_physcial_information: boolean;
    have_favourite_information: boolean;
    have_hobbies_information: boolean;
    have_dream_information: boolean;
    have_proud_moments: boolean;
    have_achievement_information: boolean;
    have_cv_information: boolean;
};

interface IFuneral {
    have_funeral_basic_information: boolean;
    have_attire: boolean;
    have_hair_and_beauty: boolean;
    have_further_items: boolean;
};

interface IUserDetails {
    id: number;
    user_id: string;
    email: string;
    full_name: string;
    photo_url: string;
    activated: boolean;
    term_and_condition: boolean;
    created_at: string;
    have_created_profile: boolean;
    have_delegate_members: boolean;
    count_delegate: number;
    have_subscription: boolean;
    have_legal_information: boolean;
    have_medical_information: boolean;
    have_investment_information: boolean;
    have_pet_information: boolean;
    have_family_detail: boolean;
    have_friend_detail: boolean;
    have_personal_letters: boolean;
    have_voice_notes: boolean;
    about_me: IAboutMe;
    funeral: IFuneral;
    have_after_wish: boolean;
};

interface ILoggedInUserApiResponse {
    id: string;
    type: string;
    attributes: IUserDetails;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    // Customizable Area Start
    progress?: number;
    // Customizable Area End
}

interface S {
  // Customizable Area Start
    drawerOpen: boolean;
    activeMenu: string;
    isAuthenticated: boolean;
    isMobile: boolean;
    profileData: IUserDetails | null;
    anchorEl: HTMLElement | null;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class WebHeaderController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  callGetLoggedInUserApiId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

      this.state = {
          drawerOpen: false,
          activeMenu: "1",
          isAuthenticated: false,
          isMobile: false,
          profileData: null,
          anchorEl: null,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getLoggedInUserDetails();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.apiSuccessCallBackController(apiRequestCallId, responseJSON);
    // Customizable Area End
  }

  // Customizable Area Start

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
      [this.callGetLoggedInUserApiId]: this.handleGetLoggedInUserResponse,
    };

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void =
        successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJSON);
    }
  };

  getLoggedInUserDetails = async () => {
    const token = await getStorageData("token");

    if(token){
        this.callGetLoggedInUserApiId = sendAPIRequest(
            configJSON.getLoggedInUserApiEndPoint,
            {
              method: configJSON.validationApiMethodType,
              headers: {
                  token,
                },
            }
        );
    }
  };

  handleGetLoggedInUserResponse = (
    responseJSON: Record<string, unknown>
  ) => {
    const response = responseJSON as {
      meta?: { message: string };
      data?: ILoggedInUserApiResponse;
    };

    if (response.data) {
      this.setState({ 
        profileData: response.data.attributes,
        isAuthenticated: true,
     });
    }
  };

  handleMenuClick = (id: string) => {
    this.setState({ activeMenu: id });
  };

  activeTab = (path: string) =>{
    let navigationUrl = window.location.href.toLowerCase()
    let navPath = path.toLowerCase()
    return navigationUrl.includes(navPath)
  }

  toggleDrawer = () => {
    this.setState({ drawerOpen: !this.state.drawerOpen });
  };

  handleProfileClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleDropdownMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleMenuItemClick = (menuItem: string) => {
    if(menuItem === "Logout"){
      this.logout();
    }
    else{
      this.setState({ anchorEl: null });
      this.handleNavigation(menuItem);
    }
  };

  logout = async () => {
    await removeStorageData("token");
    this.setState({ isAuthenticated: false, anchorEl: null });
    this.handleNavigation("LandingPage");
  };

  handleNavigation = (route: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  // Customizable Area End
}
