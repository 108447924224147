import React from "react";
import WebFooter from "../../../components/src/Footer.web";
import WebHeader from "../../../blocks/landingpage/src/WebHeader.web";
import {
    Box,
    Grid,
    Typography,
    Button,
    FormLabel,
    Container,
    Switch,
    Avatar,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText

} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import GenericModal from "../../../components/src/GenericModal";
import { Field, FieldArray, Form, Formik } from "formik";
import CustomInput from "../../../components/src/CustomInput";
import { rightTickImg } from "../../../blocks/forgot-password/src/assets";
import CustomSelect from "../../../components/src/CustomSelect";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { DialogWrapper } from "../../../blocks/customform/src/LegalInformation.web";
import { IMedia } from "../../../blocks/customform/src/LegalInformationController.web";
import ErrorMessage from "../../../components/src/ErrorMessage";
import VoiceNotesEditController, { Props, configJSON, IVoiceNotes } from "./VoiceNotesEditController.web";
import { Wrapper, theme } from "./LegalDataEdit.web"
import SettingsVoiceIcon from '@material-ui/icons/SettingsVoice';
import BreadcrumbStepper from "../../../components/src/BreadcrumbStepper.web";
import { FileDrop } from "react-file-drop";
import { uploadMediaIcon, audioIcon } from "../../../blocks/customform/src/assets";
export default class VoiceNotesEdit extends VoiceNotesEditController {

    constructor(props: Props) {
        super(props)
    }

    isIMedia(file: File | IMedia): file is IMedia {
        return (file as IMedia).content_type !== undefined;
    };

    render() {
        const addNotes = {
            delegate_id: "",
            recipient: "",
            letter: "",
            have_special_day: false,
            special_day: "",
            files: [],
        }
        const { letters, isEditNotes, VoiceNotesSteps, successFamilyDialog } = this.state
        return (
            <>
                <ThemeProvider theme={theme}>
                    <Wrapper>
                        <WebHeader navigation={this.props.navigation} />
                        <Box className="container">
                            <Container>
                                <Box>
                                    <BreadcrumbStepper steps={VoiceNotesSteps} onStepClick={(path) => this.handleNavigation(path)} />
                                </Box>
                                <Box className="legalDataWrapper">
                                    <Box className="pageTitle">
                                        <Typography data-test-id="legalDataTitle" variant="h3" className="pageTitleText">
                                            {configJSON.voiceNotes}
                                        </Typography>
                                    </Box>
                                    <Box className="formWrapper">
                                        <Formik
                                            initialValues={letters}
                                            validationSchema={this.validateFamilyDetails}
                                            onSubmit={(values) => {
                                                this.handleFamilDetailsForm(values)
                                            }}
                                            enableReinitialize={true}
                                            data-test-id="VoiceNotesForm"
                                        >
                                            {({ values, setFieldValue }) => (
                                                <Form>
                                                    <FieldArray name="voiceNotes" data-test-id="voiceFieldArray">
                                                        {({ remove, push }) => (
                                                            values.voiceNotes.map((voiceNotes: IVoiceNotes, index: number) => {
                                                                return (
                                                                    <Box mb={3} className={this.getClassName()}>
                                                                        <Grid container spacing={2}>
                                                                            <Grid item xs={12} className="policyDetailsHeaderWrapper">
                                                                                <Typography data-test-id="fieldArrayTitle" variant={this.getVaraintName()}>
                                                                                    {configJSON.personalLetterHeading}
                                                                                </Typography>
                                                                                {
                                                                                    isEditNotes && (
                                                                                        this.getMaxLetters(index, values.voiceNotes.length) ? (
                                                                                            <Button
                                                                                                className="addMoreBtn"
                                                                                                data-test-id="addMoreNotes"
                                                                                                onClick={() => push(addNotes)}
                                                                                            >
                                                                                                <AddCircleOutlineIcon className="circleOutlineIcon" />
                                                                                                {configJSON.addLettersBtnText}
                                                                                            </Button>
                                                                                        ) :
                                                                                            (
                                                                                                <Button
                                                                                                    data-test-id="removeLetters"
                                                                                                    className="addMoreBtn"
                                                                                                    onClick={() => remove(index)}
                                                                                                    >
                                                                                                    <RemoveCircleOutlineIcon className="circleOutlineIcon" />
                                                                                                    {configJSON.removeBtnText}
                                                                                                </Button>)

                                                                                    )
                                                                                }
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                                                                <FormLabel component="label">
                                                                                    {configJSON.delegateFieldLabel}<span style={{ color: "red" }}>*</span>
                                                                                </FormLabel>
                                                                                <Field
                                                                                    data-test-id="voiceDelegateID"
                                                                                    name="delegateId"
                                                                                    className="inputField selectFieldInput"
                                                                                    setValue={true}
                                                                                    as={CustomSelect}
                                                                                    disabled={this.isDisableField(index)}
                                                                                    placeholder="Select delegate user"
                                                                                    options={this.state.delegateInCharge}
                                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                                        setFieldValue(`voiceNotes.${index}.delegate_id`, e.target.value)
                                                                                    }}
                                                                                    value={values.voiceNotes[0].delegate_id}
                                                                                />
                                                                                <ErrorMessage name={`voiceNotes.${index}.delegate_id`} />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                                                                <FormLabel component="label">{configJSON.recipientFieldLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                                                                                <Field
                                                                                    as={CustomInput}
                                                                                    className="inputField"
                                                                                    size="small"
                                                                                    data-test-id="voiceRecipientOfLetter"
                                                                                    disabled={!isEditNotes}
                                                                                    name="recipientOfLetter"
                                                                                    placeholder="Enter a full name of the recipient"
                                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                                        setFieldValue(`voiceNotes.${index}.recipient`, e.target.value)
                                                                                    }}
                                                                                    value={voiceNotes.recipient}
                                                                                />
                                                                                <ErrorMessage name={`voiceNotes.${index}.recipient`} />
                                                                            </Grid>

                                                                            {isEditNotes && <Grid item xs={12}>
                                                                                <Box className="haveSpecialDayWrapper">
                                                                                    <Typography align="center" className="haveSpecialDayLabel">
                                                                                        {configJSON.haveSpecialDaySwitchLabel}
                                                                                    </Typography>
                                                                                    <Switch
                                                                                        data-test-id="haveSpecialVoiceNotes"
                                                                                        color="primary"
                                                                                        disabled={!isEditNotes}
                                                                                        checked={voiceNotes.have_special_day}
                                                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                                                                            this.handleSwitchChange(index, setFieldValue, event.target.checked)
                                                                                        }
                                                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                                                    />
                                                                                </Box>
                                                                            </Grid>}
                                                                            {voiceNotes.have_special_day && (
                                                                                <>
                                                                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                                                                        <FormLabel component="label">
                                                                                            {configJSON.specialDayFieldLabel}<span style={{ color: "red" }}>*</span>
                                                                                        </FormLabel>
                                                                                        <Field
                                                                                            data-test-id="voiceSpecialDay"
                                                                                            name="specialDay"
                                                                                            className="inputField"
                                                                                            size="small"
                                                                                            disabled={!isEditNotes}
                                                                                            as={CustomInput}
                                                                                            placeholder="Special Day/ Subject"
                                                                                            value={voiceNotes.special_day}
                                                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                                                                setFieldValue(`voiceNotes.${index}.special_day`, e.target.value)
                                                                                            }
                                                                                        />
                                                                                        <ErrorMessage name={`voiceNotes.${index}.special_day`} />
                                                                                    </Grid>
                                                                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                                                                        <FormLabel component="label">{configJSON.selectPromptLabel}</FormLabel>
                                                                                        <Field
                                                                                            name="letter"
                                                                                            className="selectField"
                                                                                            as={CustomSelect}
                                                                                            disabled={!isEditNotes}
                                                                                            setValue={true}
                                                                                            data-test-id="voicePrompts"
                                                                                            placeholder="Select delegate user"
                                                                                            options={this.state.promptsList}
                                                                                            value={voiceNotes.prompt}
                                                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                                                setFieldValue(`voiceNotes.${index}.prompt`, e.target.value)
                                                                                            }}
                                                                                        />
                                                                                    </Grid>
                                                                                </>
                                                                            )}

                                                                            <Grid item xs={12}>
                                                                                { isEditNotes &&<FileDrop
                                                                                    data-test-id="fileDrop"
                                                                                    onDrop={(files: any, event: any) => {
                                                                                        this.handleFileDrops(files, event, index, setFieldValue, voiceNotes.files)
                                                                                    }} >
                                                                                    <Typography className="title">{configJSON.uploadMedia}</Typography>
                                                                                    <Typography className="uploadInfo">
                                                                                    {configJSON.dragAndDrop1}
                                                                                    </Typography>
                                                                                    <label className="uploadBox">
                                                                                        <Button variant="contained" component="span" className="chooseFileBtn" data-test-id="uploadButton" onClick={() => this.openUploadDialog(index, setFieldValue, voiceNotes.files)}>
                                                                                        {configJSON.dragAndDrop2}
                                                                                        </Button>
                                                                                        <img alt="icon" className="uploadMediaIcon" src={uploadMediaIcon} />
                                                                                        <Typography variant="body2">{configJSON.dragAndDrop3}</Typography>
                                                                                    </label>
                                                                                </FileDrop>}
                                                                                { !isEditNotes && <Typography variant={this.getVaraintName()}>Media</Typography>}
                                                                                <div>
                                                                                    <List className="fileList" key={voiceNotes.recipient}>
                                                                                        {voiceNotes.files?.map((file, _index) => (
                                                                                            <>
                                                                                                <Box className="fileItemWrapper" key={_index}>
                                                                                                    <ListItem key={_index} className="fileItem">
                                                                                                        <ListItemAvatar><Avatar src={audioIcon} className="fileIcon" /></ListItemAvatar>
                                                                                                        <ListItemText
                                                                                                            primary={this.isIMedia(file) ? file.file_name : file.name}
                                                                                                            secondary={
                                                                                                                this.isIMedia(file)
                                                                                                                    ? `${(file.file_size / 1024).toFixed(2)} KB`
                                                                                                                    : `${(file.size / 1024).toFixed(2)} KB`
                                                                                                            }
                                                                                                        />
                                                                                                    </ListItem>
                                                                                                </Box>
                                                                                            </>
                                                                                        ))}
                                                                                    </List>
                                                                                </div>
                                                                                {this.getFileLength(voiceNotes.files.length) && (
                                                                                    <Box>
                                                                                        <Typography variant="subtitle1" align="center" className="dataNotAvailable">
                                                                                            {configJSON.dataNotAvailable}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                )}
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                );
                                                            })
                                                        )}
                                                    </FieldArray>
                                                    <Box className="btnWrapper">
                                                        <Button
                                                            className="btnSize"
                                                            variant="outlined"
                                                            onClick={() => this.handleNavigation("HomePage")}
                                                        >
                                                            {configJSON.backBtnText}
                                                        </Button>
                                                        {isEditNotes ?
                                                            <Button
                                                                variant="contained"
                                                                type="submit"
                                                                className="btnSize"
                                                                data-test-id=""
                                                            >
                                                                {configJSON.saveChangesBtnText}
                                                            </Button> :
                                                            <Button
                                                                data-test-id="editLetters"
                                                                variant="contained"
                                                                onClick={(event) => this.setEditForm(event)}
                                                                type="button"
                                                                className="btnSize"
                                                            >
                                                                {configJSON.editDetailsBtnText}
                                                            </Button>
                                                        }
                                                    </Box>
                                                </Form>)}
                                        </Formik>
                                    </Box>
                                </Box>
                            </Container>
                        </Box>
                        <WebFooter
                            handleNavigation={this.handleNavigation}
                        />
                    </Wrapper>
                    <GenericModal open={successFamilyDialog} onClose={this.closeSuccessFamilyDialog}>
                        <ThemeProvider theme={theme}>
                            <DialogWrapper>
                                <Box className="dialogContentWrapper">
                                    <Box className="dialogDescriptionWrapper">
                                        <img alt="success"
                                         src={rightTickImg} 
                                         style={{ width: "56px", height: "56px", margin: "0px auto" }} />
                                        <Typography align="center" 
                                        variant="body2">
                                            {configJSON.dialogMessageText}
                                        </Typography>
                                    </Box>
                                </Box>
                            </DialogWrapper>
                        </ThemeProvider>
                    </GenericModal>
                    <GenericModal
                        open={this.state.uploadDialog}
                        onClose={this.handleCloseDialog}
                        data-test-id="information-added-modal"
                    >
                        <ThemeProvider theme={theme}>
                            <DialogWrapper>
                                <Box className="dialogContentWrapper">
                                    <Box className="dialogDescriptionWrapper">
                                        <Typography
                                            variant="h1"
                                            align="center"
                                            data-test-id="emailSentModalTitle"
                                        >
                                            Upload Audio
                                        </Typography>
                                    </Box>
                                    <Box display="flex" mb="30px" flexDirection="column" alignItems="center">
                                        {this.state.isRecording ? <SettingsVoiceIcon fontSize="large" /> : this.getAudioURL(this.state.audioBlob) && <> <audio src={this.getAudioURL(this.state.audioBlob)} controls />
                                            <Box mt="20px">
                                                <Button
                                                    id="contained-button-file"
                                                    className="outlinedBtn"
                                                    variant="outlined"
                                                    data-test-id="retryRecording"
                                                    style={{ marginRight: '6px' }}
                                                    onClick={() => this.retryRecording()}
                                                >
                                                    Retry
                                                </Button>
                                                <Button
                                                    className="containedBtn"
                                                    data-test-id="saveRecording"
                                                    variant="contained"
                                                    onClick={
                                                        () => this.saveRecording()
                                                    }
                                                >
                                                    Save
                                                </Button>
                                            </Box>
                                        </>}
                                    </Box>
                                    <Box className="dialogBtnsWrapper">
                                        <label className="uploadBox">
                                            <input
                                                data-test-id="uploadMediaLocal"
                                                type="file"
                                                accept="audio/*"
                                                multiple
                                                style={{ display: 'none' }}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleFileUpload(event, this.state.selectedFieldValue, this.state.selectedIndex)}
                                            />
                                            <Button
                                                variant="outlined"
                                                data-test-id="uploadAudio"
                                                className="outlinedBtn"
                                                id="contained-button-file"
                                                component="span"
                                            >
                                                Upload Audio
                                            </Button>
                                        </label>
                                        <Button
                                            className="containedBtn"
                                            variant="contained"
                                            data-test-id="recordAudio"
                                            onClick={() => this.handleRecordingButton()}
                                        >
                                            {this.handleRecordingButtonText()}
                                        </Button>
                                    </Box>
                                </Box>
                            </DialogWrapper>
                        </ThemeProvider>
                    </GenericModal>
                </ThemeProvider>
            </>
        )
    }
}
