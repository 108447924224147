import React from "react";
// Customizable Area Start
import { styled, ThemeProvider } from "@material-ui/core/styles";
import FriendsDetailsEditController, {
  configJSON,
  ICloseFriendsAttributes,
  IFriendMemoriesAttributes,
  IGroupMemoriesAttributes,
  Props,
} from "./FriendsDetailsEditController.web";
import {
  Box,
  Grid,
  Typography,
  Button,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Switch,
  Container,
  createTheme,

} from "@material-ui/core";
import WebFooter from "../../../components/src/Footer.web";
import WebHeader from "../../../blocks/landingpage/src/WebHeader.web";
import { Field, FieldArray, Form, Formik, FormikValues } from "formik";
import { StyledRadio, DialogWrapper } from "../../../blocks/customform/src/LegalInformation.web";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CustomInput from "../../../components/src/CustomInput";
import ErrorMessage from "../../../components/src/ErrorMessage";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import MediaUpload from "../../../components/src/MediaUpload.web";
import MyLocationTwoToneIcon from '@material-ui/icons/MyLocationTwoTone';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import CustomDatePicker from "../../../components/src/CustomDatePicker";
import GenericModal from "../../../components/src/GenericModal";
import { rightTickImg } from "../../../blocks/forgot-password/src/assets";
import BreadcrumbStepper from "../../../components/src/BreadcrumbStepper.web";
const styledMyLocationTwoToneIcon = styled(MyLocationTwoToneIcon)({
  fill: "#A190C2",
});

const StyledAvTimerIcon = styled(AvTimerIcon)({
  fill: "#A190C2",
});
export const theme = createTheme({
  overrides: {
    MuiTypography: {
      h1: {
        fontFamily: "Quattrocento",
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "28px",
      },
      h2: {
        fontFamily: "Lato",
        fontSize: "20px",
        fontWeight: 600,
        lineHeight: "28px",
        color: "#9176C4",
      },
      h3: {
        fontFamily: "Quattrocento",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "22px",
      },
      subtitle1: {
        fontFamily: "Lato",
        fontSize: "18px",
        fontWeight: 600,
        lineHeight: "26px",
        color: "#94A3B8",
      },
      body2: {
        fontFamily: "Lato",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "#64748B",
      },
    },
    MuiFormLabel: {
      root: {
        color: "#334155",
        fontFamily: "Lato",
        fontSize: "14px",
        lineHeight: "22px",
        fontWeight: 400,
      }
    },

    MuiButton: {
      outlined: {
        width: "248px",
        height: "48px",
        color: "#9176C4",
        fontFamily: "Lato",
        textTransform: "none",
        fontSize: '16px',
        fontWeight: 600,
        borderRadius: "100px",
      },
      contained: {
        width: "248px",
        height: "48px",
        backgroundColor: "#A190C2",
        color: "#FFFFFF",
        textTransform: "none",
        fontFamily: "Lato",
        fontSize: '16px',
        fontWeight: 600,
        borderRadius: "100px",
      },
    },
    MuiRadio: {
      colorSecondary: {
        '&$checked': {
          color: "#9176C4",
        },
      }
    },
    MuiCheckbox: {
      colorPrimary: {
        '&$checked': {
          color: "#A190C2",
        },
      }
    },
    MuiSwitch: {
      root: {
        borderRadius: "40px",
        padding: 0,
        width: "48px",
        height: "28px",
      },
      switchBase: {
        color: "#fff",
        padding: "2px",
        '&$checked': {
          transform: 'translateX(20px)',
          color: "#fff",
          '& + $track': {
            opacity: 1,
            borderColor: "#A190C2 !important",
            backgroundColor: "#A190C2 !important",
          },
        },
      },
      thumb: {
        width: "24px",
        boxShadow: 'none',
        height: "24px",
      },
      track: {
        color: "fff",
        backgroundColor: "gray",
        borderRadius: "40px",
        opacity: 1,
      },
      colorPrimary: {
        '&$checked': {
          padding: "2px",
          color: "#fff",
        }
      }
    },
    MuiInputBase: {
      root: {
        minHeight: '48px',
      },
    },
  },
});
// Customizable Area End

export default class FriendsDetailsEdit extends FriendsDetailsEditController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderFriendsMemoriesForm = (
    values: FormikValues,
    setFieldValue: {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
    }) => {
    const {
      isEdit
    } = this.state;
    return (
      <FieldArray name="friend_memories_attributes" data-test-id="friendMemoriesFieldArray1">
        {({ remove, push }) => (
          values.friend_memories_attributes.map((friendMemoriesAttributes: IFriendMemoriesAttributes, index: number) => {
            return (
              <Grid item xs={12} key={index} className={`${isEdit ? "formSectionBackground" : ""}`}>
                <Grid container spacing={2}>
                  <Grid item xs={12} className="fieldArrayHeaderWrapper">
                    <Box>
                      <Typography data-test-id="favouriteMemoryFormTitleFriends" variant={this.state.isEdit ? "h3" : "h2"}>
                        {configJSON.favouriteMemoryFormTitleFriends}
                      </Typography>
                      <Typography data-test-id="friendMemoriesSubtitle" className="memoriesSubtitle">
                        {`${configJSON.memoriesSubtitleFriends} 3 ${configJSON.memoriesTextFriends}`}
                      </Typography>
                    </Box>
                    {(index === 0 && isEdit && values.friend_memories_attributes.length < 3) && (
                      <Button
                        className="addAnotherBtn"
                        data-test-id="addAnotherFriendMemoryBtn"
                        onClick={() => push({
                          title: "",
                          location: "",
                          time_stamp: "",
                          files: [],
                        })}
                      >
                        <AddCircleOutlineIcon className="circleOutlineIcon" />
                        {configJSON.addAnotherBtnTextFriends}
                      </Button>
                    )}
                    {(index !== 0 && isEdit) && (
                      <Button
                        data-test-id="removeAnotherMemoryBtn"
                        className="addAnotherBtn"
                        onClick={() => remove(index)}>
                        <RemoveCircleOutlineIcon className="circleOutlineIcon" />
                        {configJSON.removeBtnTextFriends}
                      </Button>)}
                  </Grid>
                  <Grid item lg={4}>
                    <FormLabel component="label">{configJSON.memoryTitleFieldLabelFriends}<span style={{ color: "red" }}>*</span></FormLabel>
                    <Field
                      data-test-id="memoryTitleField"
                      name="memoryTitle"
                      className="inputField"
                      size="small"
                      as={CustomInput}
                      disabled={!isEdit}
                      placeholder="Enter here"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(`friend_memories_attributes.${index}.title`, e.target.value)
                      }}
                      value={friendMemoriesAttributes.title}
                    />
                    <ErrorMessage name={`friend_memories_attributes.${index}.title`} />
                  </Grid>
                  <Grid item lg={4}>
                    <FormLabel component="label">{configJSON.friendMemoryLocationFieldLabelFriends}<span style={{ color: "red" }}>*</span></FormLabel>
                    <Field
                      data-test-id="memoryLocation"
                      name="memoryLocation"
                      className="inputField"
                      size="small"
                      as={CustomInput}
                      disabled={!isEdit}
                      placeholder="Enter a Location"
                      startIcon={styledMyLocationTwoToneIcon}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(`friend_memories_attributes.${index}.loction`, e.target.value)
                      }}
                      value={friendMemoriesAttributes.loction}
                    />
                    <ErrorMessage name={`friend_memories_attributes.${index}.loction`} />
                  </Grid>
                  <Grid item lg={4}>
                    <FormLabel component="label">{configJSON.friendMemoryTimeStampFieldLabelFriends}<span style={{ color: "red" }}>*</span></FormLabel>
                    <Field
                      data-test-id="memoryTimeStamp"
                      name="memoryTimeStamp"
                      className="inputField"
                      size="small"
                      as={CustomInput}
                      defaultValue="00:00:00"
                      disabled={!isEdit}
                      type="time"
                      placeholder="00:00:00"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(`friend_memories_attributes.${index}.time_stamp`, e.target.value)
                      }}
                      startIcon={StyledAvTimerIcon}
                      value={friendMemoriesAttributes.time_stamp}
                    />
                    <ErrorMessage name={`friend_memories_attributes.${index}.time_stamp`} />
                  </Grid>
                  <Grid item lg={12}>
                    <MediaUpload
                      data-test-id="mediaUploadForFriendMemories"
                      files={(this.state.filesForFriendMemories.length > 0 && this.state.filesForFriendMemories[index]) ? this.state.filesForFriendMemories[index] : friendMemoriesAttributes.files}
                      onUpload={(event: React.ChangeEvent<HTMLInputElement>) => this.handleFileUpload(event, setFieldValue, "friend_memories_attributes", index)}
                      readonly={!isEdit}
                      title={!isEdit ? "Media" : ""}
                    />
                    {!isEdit && this.state.filesForFriendMemories.length === 0 && (
                      <Box>
                        <Typography variant="subtitle1" align="center" className="dataNotAvailable">
                          {configJSON.dataNotAvailable}
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            );
          })
        )}
      </FieldArray>
    )
  };

  renderCloseFriendMemories = (
    values: FormikValues,
    setFieldValue: {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
    },
    closeFriendsAttributes: ICloseFriendsAttributes,
    index: number) => {
    const {
      isEdit
    } = this.state;
    return (
      <>
        <Grid item xs={12} className="fieldArrayHeaderWrapper">
          <Box>
            <Typography data-test-id="friendMemoriesFormTitle" variant={isEdit ? "h3" : "h2"}>
              {configJSON.favouriteMemoryFormTitleFriends}
            </Typography>
          </Box>
        </Grid>
        <Grid item lg={4}>
          <FormLabel component="label">{configJSON.memoryTitleFieldLabelFriends}<span style={{ color: "red" }}>*</span></FormLabel>
          <Field
            data-test-id="memoryTitlesForm"
            name="memoryTitle"
            className="inputField"
            size="small"
            as={CustomInput}
            disabled={!isEdit}
            placeholder="Enter a title for memory"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue(`close_friends_attributes.${index}.friend_memories_attributes.title`, e.target.value)
            }}
            value={closeFriendsAttributes.friend_memories_attributes.title}
          />
          <ErrorMessage name={`close_friends_attributes.${index}.friend_memories_attributes.title`} />
        </Grid>
        <Grid item lg={4}>
          <FormLabel component="label">{configJSON.friendMemoryLocationFieldLabelFriends}<span style={{ color: "red" }}>*</span></FormLabel>
          <Field
            data-test-id="memoryLocation"
            name="memoryLocation"
            className="inputField"
            size="small"
            as={CustomInput}
            disabled={!isEdit}
            placeholder="Enter a Location"
            startIcon={styledMyLocationTwoToneIcon}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue(`close_friends_attributes.${index}.friend_memories_attributes.loction`, e.target.value)
            }}
            value={closeFriendsAttributes.friend_memories_attributes.loction}
          />
          <ErrorMessage name={`close_friends_attributes.${index}.friend_memories_attributes.loction`} />
        </Grid>
        <Grid item lg={4}>
          <FormLabel component="label">{configJSON.friendMemoryTimeStampFieldLabelFriends}<span style={{ color: "red" }}>*</span></FormLabel>
          <Field
            data-test-id="memoryTimeStamp"
            name="memoryTimeStamp"
            className="inputField"
            size="small"
            as={CustomInput}
            disabled={!isEdit}
            placeholder="00:00:00"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue(`close_friends_attributes.${index}.friend_memories_attributes.time_stamp`, e.target.value)
            }}
            startIcon={StyledAvTimerIcon}
            value={closeFriendsAttributes.friend_memories_attributes.time_stamp}
          />
          <ErrorMessage name={`close_friends_attributes.${index}.friend_memories_attributes.time_stamp`} />
        </Grid>
        <Grid item lg={12}>
          <MediaUpload
            data-test-id="mediaUploadForCloseFriendMemories"
            files={(this.state.filesForCloseFriendsMemories.length > 0 && this.state.filesForCloseFriendsMemories[index]) ? this.state.filesForCloseFriendsMemories[index] : closeFriendsAttributes.friend_memories_attributes.files}
            onUpload={(event: React.ChangeEvent<HTMLInputElement>) => this.handleFileUpload(event, setFieldValue, "close_friend_memories_attributes", index)}
            readonly={!isEdit}
            title={!isEdit ? "Media" : ""}
          />
          {!isEdit && this.state.filesForCloseFriendsMemories.length === 0 && (
            <Box>
              <Typography variant="subtitle1" align="center" className="dataNotAvailable">
                {configJSON.dataNotAvailable}
              </Typography>
            </Box>
          )}
        </Grid>
      </>
    );
  };

  renderClosestFriendsForm = (
    values: FormikValues,
    setFieldValue: {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
    }) => {
    const {
      isEdit
    } = this.state;
    const max_closest_friends = this.state.subscriptionData?.features ? this.state.subscriptionData.features.max_closest_friends : 3;
    return (
      <FieldArray name="close_friends_attributes" data-test-id="closeFriendsFieldArray">
        {({ remove, push }) => (
          values.close_friends_attributes.map((closeFriendsAttributes: ICloseFriendsAttributes, index: number) => {
            return (
              <Grid item xs={12} key={index} className={`${isEdit ? "formSectionBackground" : ""}`}>
                <Grid container spacing={2}>
                  <Grid item xs={12} className="fieldArrayHeaderWrapper">
                    <Box>
                      <Typography data-test-id="closeFriendsFormTitle" variant={this.state.isEdit ? "h3" : "h2"}>
                        {configJSON.closestFriendsFormTitle}
                      </Typography>
                      <Typography data-test-id="closeFriendsFormSubtitle" className="memoriesSubtitle">
                        {`${configJSON.closestFriendsSubtitle} ${max_closest_friends} ${configJSON.closeFriendsText}`}
                      </Typography>
                    </Box>
                    {(index === 0 && isEdit && values.close_friends_attributes.length < max_closest_friends) && (
                      <Button
                        className="addAnotherBtn"
                        data-test-id="addAnothercloseFriendsFieldArrayBtn"

                        onClick={() => push({
                          full_name: "",
                          about: "",
                          location: "",
                          friend_media_attributes: {
                            image_location: "",
                            image_date: "",
                            files: [],
                          },
                          friend_memories_attributes: {
                            title: "",
                            location: "",
                            time_stamp: "",
                            files: [],
                          }
                        })}
                      >
                        <AddCircleOutlineIcon className="circleOutlineIcon" />
                        {configJSON.addAnotherBtnTextFriends}
                      </Button>
                    )}
                    {(index !== 0 && isEdit) && (
                      <Button
                        data-test-id="removeAnothercloseFriendsFieldArrayBtn"
                        className="addAnotherBtn"
                        onClick={() => remove(index)}>
                        <RemoveCircleOutlineIcon className="circleOutlineIcon" />
                        {configJSON.removeBtnTextFriends}
                      </Button>)}
                  </Grid>
                  <Grid item lg={4}>
                    <FormLabel component="label">{configJSON.closeFriendFullNameFieldLabel}</FormLabel>
                    <Field
                      data-test-id="closeFriendFullNameInputField"
                      name="closeFriendFullName"
                      className="inputField"
                      size="small"
                      as={CustomInput}
                      disabled={!isEdit}
                      placeholder="Enter full name"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(`close_friends_attributes.${index}.full_name`, e.target.value)
                      }}
                      value={closeFriendsAttributes.full_name}
                    />
                  </Grid>
                  <Grid item lg={8}>
                    <FormLabel component="label">{configJSON.closeFriendLocationFieldLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                    <Field
                      data-test-id="closeFriendLocation"
                      name="closeFriendLocation"
                      size="small"
                      as={CustomInput}
                      disabled={!isEdit}
                      placeholder="Location"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(`close_friends_attributes.${index}.loction`, e.target.value)
                      }}
                      startIcon={styledMyLocationTwoToneIcon}
                      value={closeFriendsAttributes.loction}
                    />
                    <ErrorMessage name={`close_friends_attributes.${index}.loction`} />
                  </Grid>
                  <Grid item xs={12}>
                    <FormLabel component="label">{configJSON.closeFriendLocationFieldLabel}</FormLabel>
                    <Field
                      data-test-id="aboutCloseFriend"
                      name="aboutCloseFriend"
                      size="small"
                      multiline
                      as={CustomInput}
                      disabled={!isEdit}
                      placeholder="Write about first meeting with your friend"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(`close_friends_attributes.${index}.about`, e.target.value)
                      }}
                      value={closeFriendsAttributes.about}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormLabel component="label">{configJSON.closeFriendDescriptionFieldLabel}</FormLabel>
                    <Field
                      data-test-id="closeFriendDescription"
                      name="closeFriendDescription"
                      size="small"
                      multiline
                      as={CustomInput}
                      disabled={!isEdit}
                      placeholder="Min 3 words"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(`close_friends_attributes.${index}.description`, e.target.value)
                      }}
                      value={closeFriendsAttributes.description}
                    />
                  </Grid>
                  <Grid item lg={12}>
                    <MediaUpload
                      data-test-id="mediaUploadForCloseFriend"
                      files={(this.state.filesForCloseFriends.length > 0 && this.state.filesForCloseFriends[index]) ? this.state.filesForCloseFriends[index] : closeFriendsAttributes.friend_media_attributes.files}
                      onUpload={(event: React.ChangeEvent<HTMLInputElement>) => this.handleFileUpload(event, setFieldValue, "close_friends_attributes", index)}
                      readonly={!isEdit}
                      title={!isEdit ? "Media" : ""}
                    >
                      <Box className="mediaAttribute">
                        <FormLabel component="label">{configJSON.friendMediaAttributeLocationLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                        <Field
                          data-test-id="closeFriendMediaLocation"
                          name="closeFriendMediaLocation"
                          className="mediaAttributeInput"
                          size="small"
                          as={CustomInput}
                          disabled={!isEdit}
                          placeholder="Enter a Location"
                          startIcon={styledMyLocationTwoToneIcon}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setFieldValue(`close_friends_attributes.${index}.friend_media_attributes.image_location`, e.target.value)
                          }}
                          value={closeFriendsAttributes.friend_media_attributes.image_location}
                        />
                        <ErrorMessage name={`close_friends_attributes.${index}.friend_media_attributes.image_location`} />
                      </Box>
                      <Box className="mediaAttribute">
                        <FormLabel component="label">{configJSON.friendMediaAttributeDateLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                        <Field
                          data-test-id="closeFriendMediaDate"
                          name="closeFriendMediaDate"
                          className="mediaAttributeInput"
                          size="small"
                          disabled={!isEdit}
                          as={CustomDatePicker}
                          placeholder="Enter a Date"
                          endIcon={
                            this.calenderIcon
                          }
                          onChange={(date: Date) =>
                            setFieldValue(`close_friends_attributes.${index}.friend_media_attributes.image_date`, date.toLocaleDateString("en-GB"))
                          }
                          value={closeFriendsAttributes.friend_media_attributes?.image_date}
                        />
                        <ErrorMessage name={`close_friends_attributes.${index}.friend_media_attributes.image_date`} />
                      </Box>
                    </MediaUpload>
                    {!isEdit && this.state.filesForCloseFriends.length === 0 && (
                      <Box>
                        <Typography variant="subtitle1" align="center" className="dataNotAvailable">
                          {configJSON.dataNotAvailable}
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                  <Grid item lg={12}>
                    <Box className="closeFriendMemoryWrapper">
                      <Typography align="center" className="memoryWithCloseFriendLabel">
                        {configJSON.closeFriendMemoriesSwitchLabel}
                      </Typography>
                      <Switch
                        data-test-id="memoryWithCloseFriendSwitch"
                        color="primary"
                        checked={closeFriendsAttributes.add_memory_with_close_friend}
                        disabled={!isEdit}
                        onChange={() =>
                          this.handleSwitchChange(index, setFieldValue)
                        }
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    </Box>
                  </Grid>

                  {closeFriendsAttributes.add_memory_with_close_friend && (
                    this.renderCloseFriendMemories(values, setFieldValue, closeFriendsAttributes, index)
                  )}
                </Grid>
              </Grid>
            );
          })
        )}
      </FieldArray>
    )
  };

  renderGroupMemoriesForm = (
    values: FormikValues,
    setFieldValue: {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
    }) => {
    const {
      isEdit
    } = this.state;
    const max_group_memories = this.state.subscriptionData?.features ? this.state.subscriptionData.features.max_group_memories : 1;
    return (
      <FieldArray name="group_memories_attributes" data-test-id="groupMemoriesFieldArray">
        {({ remove, push }) => (
          values.group_memories_attributes.map((groupMemoriesAttributes: IGroupMemoriesAttributes, index: number) => {
            return (
              <Grid item xs={12} key={index} className={`${isEdit ? "formSectionBackground" : ""}`}>
                <Grid container spacing={2}>
                  <Grid item xs={12} className="fieldArrayHeaderWrapper">
                    <Box>
                      <Typography data-test-id="groupMemoriesFormTitle" variant={this.state.isEdit ? "h3" : "h2"}>
                        {configJSON.groupMemoriesFormTitleFriends}
                      </Typography>
                    </Box>
                    {(index === 0 && isEdit && values.group_memories_attributes.length < max_group_memories) && (
                      <Button
                        className="addAnotherBtn"
                        data-test-id="addAnotherGroupMemoryBtnvgh"
                        onClick={() => push({
                          friend_name: [],
                          files: [],
                        })}
                      >
                        <AddCircleOutlineIcon className="circleOutlineIcon" />
                        {configJSON.addAnotherBtnTextFriends}
                      </Button>
                    )}
                    {(index !== 0 && isEdit) && (
                      <Button
                        data-test-id="removeAnotherMemory"
                        className="addAnotherBtn"
                        onClick={() => remove(index)}>
                        <RemoveCircleOutlineIcon className="circleOutlineIcon" />
                        {configJSON.removeBtnTextFriends}
                      </Button>)}
                  </Grid>
                  {this.renderFriendNameFields(values, setFieldValue, groupMemoriesAttributes, index)}
                  <Grid item lg={12}>
                    <MediaUpload
                      data-test-id="mediaUploadForGroupMemories"
                      files={(this.state.filesForGroupMemories.length > 0 && this.state.filesForGroupMemories[index]) ? this.state.filesForGroupMemories[index] : groupMemoriesAttributes.files}
                      onUpload={(event: React.ChangeEvent<HTMLInputElement>) => this.handleFileUpload(event, setFieldValue, "group_memories_attributes", index)}
                      readonly={!isEdit}
                      title={!isEdit ? "Media" : ""}

                    />
                    {!isEdit && this.state.filesForGroupMemories.length === 0 && (
                      <Box>
                        <Typography variant="subtitle1" align="center" className="dataNotAvailable">
                          {configJSON.dataNotAvailable}
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            );
          })
        )}
      </FieldArray>
    )
  };

  renderFriendNameFields = (
    values: FormikValues,
    setFieldValue: {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
    },
    groupMemoriesAttributes: IGroupMemoriesAttributes,
    groupIndex: number) => {
    const {
      isEdit
    } = this.state;
    return (
      <FieldArray name={`group_memories_attributes.${groupIndex}.friend_name`} data-test-id="groupFriendNameFieldArrayaaa">
        {({ push }) => (
          <>
            {values.group_memories_attributes[groupIndex].friend_name.map((friendName: string, index: number) => {
              return (
                <>
                  <Grid item xl={4} >
                    <FormLabel component="label">{configJSON.friendNameFieldLabelFriends}</FormLabel>
                    <Field
                      data-test-id="groupFriendName"
                      name="groupFriendName"
                      className="inputField"
                      size="small"
                      as={CustomInput}
                      disabled={!isEdit}
                      placeholder="Enter friend name"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(`group_memories_attributes.${groupIndex}.friend_name.${index}`, e.target.value)
                      }}
                      value={friendName}
                    />
                  </Grid>
                  {(index === groupMemoriesAttributes.friend_name.length - 1 && isEdit) && (
                    <Grid item xs={4} className="fieldArrayHeaderWrapper">
                      <Button
                        className="addAnotherBtn"
                        data-test-id="addMoreFriendNameBtn"
                        onClick={() => push("")}
                        style={{ marginTop: "auto" }}
                      >
                        <AddCircleOutlineIcon className="circleOutlineIcon" />
                        {configJSON.addMoreBtnTextFriends}
                      </Button>
                    </Grid>
                  )}
                </>
              );
            })}
          </>
        )}
      </FieldArray>
    )
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      friendsDetails,
      showGroupMemory,
      openDialog,
      isEdit,
      friendSteps
    } = this.state;

    return (
      <>
        <ThemeProvider theme={theme}>
          <Wrapper>
            <WebHeader navigation={this.props.navigation} />
            <Box className="containerBox">
              <Container>
                <Box>
                  <BreadcrumbStepper data-test-id="breadcrumbStepperid" steps={friendSteps} onStepClick={(path) => this.handleNavigation(path)} />
                </Box>
                <Box className="friendsDetailsWrapper">
                  <Box className="pageTilteWrapper">
                    <Typography data-test-id="pageTilteWrapper" variant="h3" className="titleText">
                      {configJSON.friendsDetailsTitle}
                    </Typography>
                    <Typography variant="h3" className="messageText">
                      <InfoOutlinedIcon className="infoIcon" />
                      <span>{configJSON.messageText}</span>
                    </Typography>
                  </Box>
                  <Box className="formWrapperBox">
                    <Formik
                      initialValues={friendsDetails}
                      validationSchema={this.validationSchema(isEdit)}
                      onSubmit={(values) => {
                        this.handleFriendsDetailsFormSubmit(values)
                      }}
                      enableReinitialize={true}
                      data-test-id="friendsDetailsForm"
                    >
                      {({ values, errors, touched, setFieldValue }) => (
                        <Form>
                          <Box className="formWrapperBox">
                            <Box>
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <FormLabel component="label" className="questionLabel">
                                    {configJSON.friendsDetailsEditQuestion1}
                                  </FormLabel>
                                  <Field
                                    data-test-id="friendName"
                                    className="inputField multilineInput"
                                    name="friendName"
                                    as={CustomInput}
                                    disabled={!isEdit}
                                    placeholder="Enter full name"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                      setFieldValue(`friend_name`, e.target.value)
                                    }}
                                    value={values.friend_name}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <FormLabel component="label" className="questionLabel">
                                    {configJSON.friendsDetailsEditQuestion2}
                                  </FormLabel>
                                  <Field
                                    data-test-id="friendSummary"
                                    name="friendSummary"
                                    className="multilineInput"
                                    as={CustomInput}
                                    disabled={!isEdit}
                                    multiline
                                    placeholder="Write about first meeting with your best friend"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                      setFieldValue(`friend_summary`, e.target.value)
                                    }}
                                    value={values.friend_summary}
                                  />
                                </Grid>
                                <Grid item xs={12} style={{ marginLeft: "16px" }}>
                                  <FormLabel component="label" className="questionLabel">
                                    {configJSON.locationFieldLabelFriends}
                                    <span style={{ color: "red" }}>*</span>
                                  </FormLabel>
                                  <Field
                                    data-test-id="location"
                                    name="location"
                                    className="inputField"
                                    as={CustomInput}
                                    disabled={!isEdit}
                                    startIcon={styledMyLocationTwoToneIcon}
                                    placeholder="Location"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                      setFieldValue(`location`, e.target.value)
                                    }}
                                    value={values.location}
                                    error={touched.location && errors.location}
                                    errorMsg={errors.location}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <FormLabel component="label" className="questionLabel">
                                    {configJSON.friendsDetailsEditQuestion3}
                                  </FormLabel>
                                  <Field
                                    data-test-id="description"
                                    name="description"
                                    className="multilineInput"
                                    as={CustomInput}
                                    disabled={!isEdit}
                                    multiline
                                    placeholder="Min 3 words"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                      setFieldValue(`description`, e.target.value)
                                    }}
                                    value={values.description}
                                  />
                                  <Box ml={2}>
                                    <MediaUpload
                                      data-test-id="mediaUploadForFriend"
                                      files={this.state.filesForFriendMedia.length > 0 ? this.state.filesForFriendMedia : friendsDetails.friend_media_attributes.files}
                                      onUpload={(event: React.ChangeEvent<HTMLInputElement>) => this.handleFileUpload(event, setFieldValue, "friend_media_attributes")}
                                      readonly={!isEdit}
                                      title={!isEdit ? "Media" : ""}


                                    >
                                      <Box className="mediaAttribute">
                                        <FormLabel component="label">{configJSON.friendMediaAttributeLocationLabelFriends}<span style={{ color: "red" }}>*</span></FormLabel>
                                        <Field
                                          data-test-id="friendMediaLocation"
                                          name="friendMediaLocation"
                                          className="mediaAttributeInput"
                                          size="small"
                                          as={CustomInput}
                                          disabled={!isEdit}
                                          placeholder="Enter a Location"
                                          startIcon={styledMyLocationTwoToneIcon}
                                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setFieldValue(`friend_media_attributes.image_location`, e.target.value)
                                          }}
                                          value={values.friend_media_attributes.image_location}
                                          error={touched.friend_media_attributes?.image_location && errors.friend_media_attributes?.image_location}
                                          errorMsg={errors.friend_media_attributes?.image_location}
                                        />
                                      </Box>
                                      <Box className="mediaAttribute">
                                        <FormLabel component="label">{configJSON.friendMediaAttributeDateLabelFriends}<span style={{ color: "red" }}>*</span></FormLabel>
                                        <Field
                                          data-test-id="friendMediaDate"
                                          name="friendMedia"
                                          className="mediaAttributeInput"
                                          size="small"
                                          disabled={!isEdit}
                                          as={CustomDatePicker}
                                          placeholder="Enter a Date"
                                          endIcon={
                                            this.calenderIcon
                                          }
                                          onChange={(date: Date) =>
                                            setFieldValue("friend_media_attributes.image_date", date.toLocaleDateString("en-GB"))
                                          }
                                          value={values.friend_media_attributes?.image_date}
                                          error={touched.friend_media_attributes?.image_date && errors.friend_media_attributes?.image_date}
                                          errorMsg={errors.friend_media_attributes?.image_date}
                                        />
                                      </Box>

                                    </MediaUpload>
                                    {!isEdit && this.state.filesForFriendMedia.length === 0 && (
                                      <Box>
                                        <Typography variant="subtitle1" align="center" className="dataNotAvailable">
                                          {configJSON.dataNotAvailable}
                                        </Typography>
                                      </Box>
                                    )}
                                  </Box>
                                </Grid>
                                <Grid item xs={12}>
                                  {isEdit && (
                                    <Box>
                                      <FormLabel component="label" className="questionLabel">
                                        {configJSON.friendsDetailsEditQuestion4}
                                      </FormLabel>
                                      <RadioGroup
                                        className="radioGroup"
                                        data-test-id="haveFavouriteMemory"
                                        aria-label="haveFavouriteMemory"
                                        name="haveFavouriteMemory"
                                        value={values.have_favourite_memory}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                          setFieldValue("have_favourite_memory", e.target.value);
                                        }}
                                      >
                                        <FormControlLabel
                                          className="radioLabel"
                                          value="1"
                                          control={<StyledRadio />}
                                          label="Yes"
                                        />
                                        <FormControlLabel
                                          className="radioLabel"
                                          value="0"
                                          control={<StyledRadio />}
                                          label="No"
                                        />
                                      </RadioGroup>
                                    </Box>
                                  )}
                                  {!isEdit && values.have_favourite_memory === "0" && (
                                    <Box>
                                      <Typography variant="h2">
                                        {configJSON.friendsMemoriesFormTitle}
                                      </Typography>
                                      <Typography variant="subtitle1" align="center" className="dataNotAvailable">
                                        {configJSON.dataNotAvailable}
                                      </Typography>
                                    </Box>
                                  )}

                                </Grid>
                                {values.have_favourite_memory === "1" && (
                                  this.renderFriendsMemoriesForm(values, setFieldValue)
                                )}

                                <Grid item xs={12}>
                                  {isEdit && (
                                    <Box>
                                      <FormLabel component="label" className="questionLabel">
                                        {configJSON.friendsDetailsEditQuestion5}
                                      </FormLabel>
                                      <RadioGroup
                                        className="radioGroup"
                                        data-test-id="haveClosestFriend"
                                        aria-label="haveClosestFriend"
                                        name="haveClosestFriend"
                                        value={values.have_closest_friend}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                          setFieldValue("have_closest_friend", e.target.value);
                                        }
                                        }
                                      >
                                        <FormControlLabel
                                          className="radioLabel"
                                          value="1"
                                          control={<StyledRadio />}
                                          label="Yes"
                                        />
                                        <FormControlLabel
                                          className="radioLabel"
                                          value="0"
                                          control={<StyledRadio />}
                                          label="No"
                                        />
                                      </RadioGroup>
                                    </Box>
                                  )}
                                  {!isEdit && values.have_closest_friend === "0" && (
                                    <Box>
                                      <Typography variant="h2">
                                        {configJSON.friendsClosestFriendsForm}
                                      </Typography>
                                      <Typography variant="subtitle1" align="center" className="dataNotAvailable">
                                        {configJSON.dataNotAvailable}
                                      </Typography>
                                    </Box>
                                  )}
                                </Grid>
                                {values.have_closest_friend === "1" && (
                                  this.renderClosestFriendsForm(values, setFieldValue)
                                )}
                                <Grid item xs={12}>
                                  {isEdit && (
                                    <Box>
                                      <FormLabel component="label" className="questionLabel">
                                        {configJSON.friendsDetailsEditQuestion6}
                                      </FormLabel>
                                      <RadioGroup
                                        className="radioGroup"
                                        data-test-id="haveGroupMemory"
                                        aria-label="haveGroupMemory"
                                        name="haveGroupMemory"
                                        value={values.have_group_memory}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                          setFieldValue("have_group_memory", e.target.value);

                                        }
                                        }
                                      >
                                        <FormControlLabel
                                          className="radioLabel"
                                          value="1"
                                          control={<StyledRadio />}
                                          label="Yes"
                                        />
                                        <FormControlLabel
                                          className="radioLabel"
                                          value="0"
                                          control={<StyledRadio />}
                                          label="No"
                                        />
                                      </RadioGroup>
                                    </Box>
                                  )}
                                  {!isEdit && values.have_group_memory === "0" && (
                                    <Box>
                                      <Typography variant="h2">
                                        {configJSON.friendsGroupMemoriesForm}
                                      </Typography>
                                      <Typography variant="subtitle1" align="center" className="dataNotAvailable">
                                        {configJSON.dataNotAvailable}
                                      </Typography>
                                    </Box>
                                  )}

                                </Grid>
                                {values.have_group_memory === "1" && (
                                  this.renderGroupMemoriesForm(values, setFieldValue)
                                )}
                              </Grid>
                            </Box>
                            <Box className="btnWrapperBox">
                              <Button
                                className="btnSize"
                                variant="outlined"
                                data-test-id="skipForNow"
                                onClick={() => this.handleNavigation("HomePage")}
                              >
                                {configJSON.backBtnText}
                              </Button>

                              {isEdit ? (
                                <Button
                                  type="submit"
                                  data-test-id="saveAndNextBtn"
                                  className="btnSize"
                                  variant="contained"
                                >
                                  {configJSON.saveChangesBtnText}
                                </Button>
                              ) : (
                                <Button
                                  className="btnSize"
                                  data-test-id="editDetailsBtn"
                                  variant="contained"
                                  type="button"
                                  onClick={this.handleEditBtnClickFrd}
                                >
                                  {configJSON.editDetailsBtnText}
                                </Button>
                              )}
                            </Box>
                          </Box>
                        </Form>
                      )}
                    </Formik>
                  </Box>
                </Box>
              </Container>

            </Box>
            <WebFooter
              handleNavigation={this.handleNavigation}
            />
          </Wrapper>
        </ThemeProvider>
        <GenericModal open={openDialog} data-test-id="information-added-modal" onClose={this.handleCloseDialog}>
          <ThemeProvider theme={theme}>
            <DialogWrapper>
              <Box className="dialogContentWrapper">
                <Box className="dialogDescriptionWrapper">
                  <img alt="success" src={rightTickImg} style={{ width: "56px", height: "56px", margin: "0px auto" }} />
                  <Typography align="center" variant="body2">
                    {configJSON.dialogMessageText}
                  </Typography>
                </Box>
              </Box>
            </DialogWrapper>
          </ThemeProvider>
        </GenericModal>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const Wrapper = styled("div")(({ theme }) => ({
  "& .containerBox": {
    display: "flex",
    gap: "72px",
    padding: "50px 72px",
  },
  "& .friendsDetailsWrapper": {
    width: "100%",
  },
  "& .pageTilteWrapper": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "32px 0",
  },
  "& .fieldArrayHeaderWrapper": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  "& .messageText": {
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 500,
    color: "#9176C4",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    gap: "4px",
  },

  "& .memoriesSubtitle": {
    color: "#475569",
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "24px",
  },
  "& .infoIcon": {
    fontSize: "20px",
  },
  "& .formWrapperBox": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
  "& .formSectionBackground": {
    borderRadius: "8px",
    padding: "24px",
    backgroundColor: "rgba(206, 195, 229, 0.1)",
  },
  "& .questionLabel": {
    color: "#1E293B",
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "22px",
  },
  "& .multilineInput": {
    marginLeft: "16px",
  },
  "& .selectField": {
    display: "block",
    width: "300px",
    height: "48px",
    borderRadius: "8px",
    margin: "5px 0px",
  },
  "& .inputField": {
    height: "48px",
    width: "275px",
    borderRadius: "8px",
  },
  "& .mediaAttributeInput": {
    height: "48px",
    maxWidth: "260px",
    borderRadius: "8px",
  },
  "& .radioGroup": {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: "16px",
    gap: "35px",
  },
  "& .radioLabel": {
    color: '#1E293B',
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "22px",
    '& .MuiRadio-colorSecondary.Mui-checked': {
      color: '#9176C4',
    },
  },
  "& .closeFriendMemoryWrapper": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .memoryWithCloseFriendLabel": {
    fontFamily: "Lato",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#1E293B",
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  "& .btnWrapperBox": {
    marginTop: "72px",
    display: "flex",
    gap: "8px",
    justifyContent: "flex-end",
  },
  "& .btnSize": {
    width: "158px",
    height: "48px",
  },
  "& .circleOutlineIcon": {
    fontSize: "16px",
    marginRight: "8px",
  },
  "& .addAnotherBtn": {
    display: "flex",
    color: "#9176C4",
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    textTransform: "none",
    alignItems: "center",
    '&:hover': {
      textDecoration: "underline",
      backgroundColor: "transparent",
    },
  },
  "& .mediaAttribute": {
    display: "flex",
    flexDirection: "column",
  },
  "& .calenderIconStyle": {
    cursor: "grab",
  },
  "& .dataNotAvailable": {
    margin: "24px 0px",
    color: "#94A3B8"
  },
  [theme.breakpoints.down(740)]: {
    "& .containerBox": {
      padding: "28px 16px",
    },
  },
}));
// Customizable Area End
