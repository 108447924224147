import React from "react";
import WebFooter from "../../../components/src/Footer.web";
import WebHeader from "../../../blocks/landingpage/src/WebHeader.web";
import {
    Box,
    Grid,
    Typography,
    Button,
    FormLabel,
    Container,
    Switch

} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import GenericModal from "../../../components/src/GenericModal";
import { Field, FieldArray, Form, Formik } from "formik";
import CustomInput from "../../../components/src/CustomInput";
import { rightTickImg } from "../../../blocks/forgot-password/src/assets";
import CustomSelect from "../../../components/src/CustomSelect";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import MediaUpload from "../../../components/src/MediaUpload.web";
import { DialogWrapper } from "../../../blocks/customform/src/LegalInformation.web";
import ErrorMessage from "../../../components/src/ErrorMessage";
import PersonalLettersEditController, { Props, configJSON, IPersonalLetter } from "./PersonalLettersEditController.web";
import { Wrapper, theme } from "./LegalDataEdit.web"
import BreadcrumbStepper from "../../../components/src/BreadcrumbStepper.web";
export default class PersonalLettersEdit extends PersonalLettersEditController {

    constructor(props: Props) {
        super(props)
    }


    render() {
        const { letters, isEditLetters, personalLetterSteps, successFamilyDialog } = this.state
        return (
            <>
                <ThemeProvider theme={theme}>
                    <Wrapper>
                        <WebHeader navigation={this.props.navigation} />
                        <Box className="container">
                            <Container>
                                <Box>
                                    <BreadcrumbStepper steps={personalLetterSteps} onStepClick={(path) => this.handleNavigation(path)} />
                                </Box>
                                <Box className="legalDataWrapper">
                                    <Box className="pageTitle">
                                        <Typography data-test-id="legalDataTitle" variant="h3" className="pageTitleText">
                                            {configJSON.personalLettersPageTitle}
                                        </Typography>
                                    </Box>
                                    <Box className="formWrapper">
                                        <Formik
                                            initialValues={letters}
                                            validationSchema={this.validateFamilyDetails}
                                            onSubmit={(values) => {
                                                this.handleFamilDetailsForm(values)
                                            }}
                                            enableReinitialize={true}
                                            data-test-id="FamilyForm"
                                        >
                                            {({ values, setFieldValue }) => (
                                                <Form>
                                                    <FieldArray name="personal_letter" data-test-id="personalFieldArray">
                                                        {({ remove, push }) => (
                                                            values.personal_letter.map((personalLetter: IPersonalLetter, index: number) => {
                                                                return (
                                                                    <Box mb={3} className={this.getClassName()}>
                                                                        <Grid container spacing={2}>
                                                                            <Grid item xs={12} className="policyDetailsHeaderWrapper">
                                                                                <Typography data-test-id="fieldArrayTitle" variant={this.getVaraintName()}>
                                                                                    {configJSON.personalLetterHeading}
                                                                                </Typography>
                                                                                {
                                                                                    isEditLetters && (
                                                                                        this.getMaxLetters(index, values.personal_letter.length) ? (
                                                                                            <Button
                                                                                                className="addMoreBtn"
                                                                                                data-test-id="addMoreLetters"
                                                                                                onClick={() => push({
                                                                                                    delegate_id: "",
                                                                                                    recipient: "",
                                                                                                    letter: "",
                                                                                                    have_special_day: false,
                                                                                                    special_day: "",
                                                                                                    files: [],
                                                                                                })}
                                                                                            >
                                                                                                <AddCircleOutlineIcon className="circleOutlineIcon" />
                                                                                                {configJSON.addLettersBtnText}
                                                                                            </Button>
                                                                                        ) :
                                                                                            (
                                                                                                <Button
                                                                                                    className="addMoreBtn"
                                                                                                    data-test-id="removeLetters"
                                                                                                    onClick={() => remove(index)}>
                                                                                                    <RemoveCircleOutlineIcon className="circleOutlineIcon" />
                                                                                                    {configJSON.removeBtnText}
                                                                                                </Button>)

                                                                                    )
                                                                                }
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                                                                <FormLabel component="label">
                                                                                    {configJSON.delegateFieldLabel}<span style={{ color: "red" }}>*</span>
                                                                                </FormLabel>
                                                                                <Field
                                                                                    data-test-id="delegateId"
                                                                                    className="inputField selectFieldInput"
                                                                                    setValue={true}
                                                                                    as={CustomSelect}
                                                                                    name="delegateId"
                                                                                    disabled={this.isDisableField(index)}
                                                                                    options={this.state.delegateInCharge}
                                                                                    value={values.personal_letter[0].delegate_id}
                                                                                    placeholder="Select delegate user"
                                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                                        setFieldValue(`personal_letter.${index}.delegate_id`, e.target.value)
                                                                                    }}
                                                                                />
                                                                                <ErrorMessage name={`personal_letter.${index}.delegate_id`} />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                                                                <FormLabel component="label">{configJSON.recipientFieldLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                                                                                <Field
                                                                                    className="inputField"
                                                                                    as={CustomInput}
                                                                                    size="small"
                                                                                    data-test-id="recipientOfLetter"
                                                                                    disabled={!isEditLetters}
                                                                                    name="recipientOfLetter"
                                                                                    placeholder="Enter a full name of the recipient"
                                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                                        setFieldValue(`personal_letter.${index}.recipient`, e.target.value)
                                                                                    }}
                                                                                    value={personalLetter.recipient}
                                                                                />
                                                                                <ErrorMessage name={`personal_letter.${index}.recipient`} />
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <FormLabel component="label">{configJSON.letterFieldLabel}</FormLabel>
                                                                                <Field
                                                                                    name="letter"
                                                                                    size="medium"
                                                                                    data-test-id="personalLetters"
                                                                                    multiline
                                                                                    disabled={!isEditLetters}
                                                                                    as={CustomInput}
                                                                                    placeholder="Please type your letter here or upload."
                                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                                                        setFieldValue(`personal_letter.${index}.letter`, e.target.value)
                                                                                    }
                                                                                    value={personalLetter.letter}
                                                                                />
                                                                            </Grid>
                                                                            {isEditLetters && <Grid item xs={12}>
                                                                                <Box className="haveSpecialDayWrapper">
                                                                                    <Typography align="center" className="haveSpecialDayLabel">
                                                                                        {configJSON.haveSpecialDaySwitchLabel}
                                                                                    </Typography>
                                                                                    <Switch
                                                                                        data-test-id="haveSpecialDaySwitch"
                                                                                        color="primary"
                                                                                        disabled={!isEditLetters}
                                                                                        checked={personalLetter.have_special_day}
                                                                                        onChange={(event: any) =>
                                                                                            this.handleSwitchChange(index, setFieldValue, event.target.checked)
                                                                                        }
                                                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                                                    />
                                                                                </Box>
                                                                            </Grid>}
                                                                            {personalLetter.have_special_day && (
                                                                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                                                                    <FormLabel component="label">
                                                                                        {configJSON.specialDayFieldLabel}<span style={{ color: "red" }}>*</span>
                                                                                    </FormLabel>
                                                                                    <Field
                                                                                        data-test-id="specialDay"
                                                                                        name="specialDay"
                                                                                        className="inputField"
                                                                                        size="small"
                                                                                        disabled={!isEditLetters}
                                                                                        as={CustomInput}
                                                                                        placeholder="Special Day/ Subject"
                                                                                        value={personalLetter.special_day}
                                                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                                                            setFieldValue(`personal_letter.${index}.special_day`, e.target.value)
                                                                                        }
                                                                                    />
                                                                                    <ErrorMessage name={`personal_letter.${index}.special_day`} />
                                                                                </Grid>
                                                                            )}

                                                                            <Grid item xs={12}>
                                                                                <MediaUpload
                                                                                    data-test-id="lettersUpload"
                                                                                    files={personalLetter.files}
                                                                                    onUpload={(event: React.ChangeEvent<HTMLInputElement>) => this.handlePersonalLettersUpload(event, setFieldValue, index, personalLetter.files)}
                                                                                    readonly={!isEditLetters}
                                                                                    title={this.getTitle()}
                                                                                />
                                                                                {this.getFileLength(personalLetter.files.length) && (
                                                                                    <Box>
                                                                                        <Typography variant="subtitle1" align="center" className="dataNotAvailable">
                                                                                            {configJSON.dataNotAvailable}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                )}
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                );
                                                            })
                                                        )}
                                                    </FieldArray>
                                                    <Box className="btnWrapper">
                                                        <Button
                                                            data-test-id="cancelChanges"
                                                            variant="outlined"
                                                            className="btnSize"
                                                            onClick={() => this.handleNavigation("HomePage")}
                                                        >
                                                            {configJSON.backBtnText}
                                                        </Button>
                                                        {isEditLetters ?
                                                            <Button
                                                                variant="contained"
                                                                className="btnSize"
                                                                type="submit"
                                                            >
                                                                {configJSON.saveChangesBtnText}
                                                            </Button> :
                                                            <Button
                                                                data-test-id="editLetters"
                                                                variant="contained"
                                                                type="button"
                                                                onClick={(event) => this.setEditForm(event)}
                                                                className="btnSize"
                                                            >
                                                                {configJSON.editDetailsBtnText}
                                                            </Button>
                                                        }
                                                    </Box>
                                                </Form>)}
                                        </Formik>
                                    </Box>
                                </Box>
                            </Container>
                        </Box>
                        <WebFooter
                            handleNavigation={this.handleNavigation}
                        />
                    </Wrapper>
                    <GenericModal open={successFamilyDialog} data-test-id="investmentFunds" onClose={this.closeSuccessFamilyDialog}>
                        <ThemeProvider theme={theme}>
                            <DialogWrapper>
                                <Box className="dialogContentWrapper">
                                    <Box className="dialogDescriptionWrapper">
                                        <img alt="success" src={rightTickImg} style={{ width: "56px", height: "56px", margin: "0px auto" }} />
                                        <Typography align="center" variant="body2" data-test-id="dialogMessage">
                                            {configJSON.dialogMessageText}
                                        </Typography>
                                    </Box>
                                </Box>
                            </DialogWrapper>
                        </ThemeProvider>
                    </GenericModal>
                </ThemeProvider>
            </>
        )
    }
}
